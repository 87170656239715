/* @media (max-width: 500px) {
  .root {
    --flexMarginTD: 10px;
    --flexMarginLR: 10px;
    --flexWidth: 100px;
    --borderRadius: 50px;
    --sectionHeight: 50px;
  }

  .mealButton {
    min-width: 40%;
  }
} */
.planHeader{
  font-size: 25px; 
  font-weight: bold;
  margin-top: 30px;
}

.mobile {
  display: none !important;
}
.container {
  margin-left: 8%;
  margin-right: 8%;
  /* text-align: center; */
  /*margin-left: 20px;
  margin-right: 20px;
  border: solid;*/
}
.containerSplit {
  text-align: center;
  display: flex;
  /*margin-left: 20px;
  margin-right: 20px;
  border: solid;*/
}

.logo {
  display: flex;
  width: 50%;
  flex-direction: row;
  margin-top: 50px;
  /*padding: 0px;*/
  text-align: left;
  font-size: 20px;
  /*border: dotted;*/
}
.logo > p {
  display: block;
  padding: 0px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 20px;
}

.menuSection {
  margin: 50px 0px 50px 0px;
  /*border: dashed;*/
}

.priceCalculation {
  display: inline-flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin-top: 80px;
  padding-bottom: 50px;
  /*border: solid;*/
}

/*.perMealDeal {
  text-align: center;
  font-size: 45px;
  margin-top: 200px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-weight: bold;
}*/
.perMealDeal {
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
}

.priceFormula {
  font-weight: bold;
  font-size: 70px;
  text-align: center;
  margin: 0px 30px 0px 30px;
  /*border: dotted;*/
}

.priceFormula2 {
  /*font-weight: bold;*/
  font-size: 25px;
  text-align: center;
  /*border: dashed;*/
  white-space: nowrap;
  /*padding-bottom: 50px;*/
  /*margin-top: 35px;*/
  margin: 35px 0px 0px 30px;
}

.priceTotal {
  display: flex;
  border: solid;
  border-radius: 25px;
  border-color: #ff6505;
  height: 120px;
}
.chargeTotal {
  display: flex;
  border: solid;
  border-radius: 25px;
  border-color: #ff6505;
  height: 80px;
  justify-content: center;
}
.chargeText {
  font-size: 20px;
  padding-top: 20px;
}
.chargeAmount {
  font-size: 40px;
  font-weight: bold;
  padding-top: 5px;
  padding-left: 20px;
}

/*.priceWaitMessage {
  font-weight: bold;
  font-size: 50px;
  text-align: center;
  margin: 50px 0px 0px 0px;
}*/
.priceWaitMessage {
  font-weight: bold;
  font-size: 50px;
  float: left;
  /*margin: 0px 100px 0px 0px;*/
  padding-bottom: 50px;
}

.priceSymbol {
  font-weight: bold;
  font-size: 70px;
  text-align: center;
  /*border: double;*/
}

/*.priceSpaceBig {
  width: 60px;
  border: double;
}*/

.priceSpaceSmall {
  width: 40px;
  /*border: double;*/
}

.priceSubtext {
  font-size: 25px;
  text-align: center;
  /*border: solid;*/
}

.input {
  width: 903px;
  height: 2.2rem;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  margin: 0px 0px 15px 0px;
  padding: 6px 0px 6px 10px;
}

.inputContactLeft {
  width: 440px;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  margin: 0px 24px 15px 0px;
  padding: 6px 0px 6px 10px;
}

.inputContactRight {
  width: 440px;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  margin: 0px 0px 15px 0px;
  padding: 6px 0px 6px 10px;
}

.box {
  /* padding: 20px; */
  margin-left: 100px;
  text-align: left;
  /*border: solid;
  border-color: red;*/
}
.boxRight {
  /* padding: 20px; */
  width: 42%;
  /*margin-right: 100px;*/
  /*padding-left: 100px*/
  /*margin-right: 8%;
  padding-left: 8%;*/
  display: flex;
  margin-top: 30px;
}

.boxPDleft {
  /* padding: 20px; */
  text-align: left;
  border: solid;
  border-color: red;
  width: 20%;
  margin-left: 100px;
}
.boxPDright {
  /* padding: 20px; */
  text-align: right;
  border: solid;
  border-color: red;
  width: 20%;
}
/*.box1 {
  padding: 10px;
  border: solid;
  border-color: blue;
}*/
.box2 {
  display: flex;
  padding: 0px;
  background-color: transparent;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 30px;
  text-align: left;
}

.boxScroll {
  /* padding: 20px; */
  margin-left: 100px;
  text-align: left;
  height: 200px;
  overflow-y: auto;
  width: 40%;
  border: solid;
  border-color: red;
  flex: inline-block;
  padding-bottom: 20px;
}

.forty {
  width: 40px;
  height: 40px;
  margin: 5px;
}
.bold {
  padding: 10px 10px 10px 0px;
  font-size: 15px;
  font-weight: bold;
  color: black;
}
.subHeader {
  padding: 10px 10px 10px 0px;
  font-size: 20px;
  font-weight: 500;
  color: black;
}
.center {
  display: block;
  margin: auto;
}

.mealButtonWrapper {
  display: inline-block;
  margin-right: 35px;
  margin-bottom: 50px;
  width: 120px;
  height: 120px;
}

/*.mealButton {
  color: #000000;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 2px #d3d3d3;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 0.75rem 0.75rem;
  margin-top: 15px;
  align-content: center;
  flex: 1;
  min-width: 20%;
  font-size: 13px;
  font-weight: 600;
  outline: 0;
  width: 100%;
}*/

.deliverySubtext {
  color: #000000;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 2px #d3d3d3;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  margin: 5px;
  text-align: center;
  flex: 1;
  min-width: 20%;
  font-size: 13px;
  font-weight: 600;
  outline: 0;
}

.deliveryButton {
  color: #000000;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 2px #d3d3d3;
  background-color: #ffffff;
  /*border-radius: 25px;*/
  padding: 0.5rem 0.5rem;
  margin: 5px;
  align-content: center;
  flex: 1;
  min-width: 20%;
  font-size: 13px;
  font-weight: 600;
  outline: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  border-radius: 0;
}

.deliveryButtonCurrent {
  color: #000000;
  background-color: #ffffff;
  margin: 5px;
  align-content: center;
  flex: 1;
  min-width: 20%;
  font-size: 10px;
  font-weight: 600;
  outline: 0;
  height: 100px;
  width: 100px;
  border: solid;
  border-color: #ff6505;
  box-shadow: none !important;
  outline: 0;
}

/*.deliveryButtonSelected {
  background-color: #FFBA00;
  outline: 0;
}*/
.deliveryButtonSelected {
  /*background-color: #FFBA00;*/
  border: solid;
  border-color: #ff6505;
  box-shadow: none !important;
  outline: 0;
}
.deliveryButtonSelected:focus {
  outline: 0 !important;
}
.deliveryButtonSelected:active {
  outline: 0 !important;
}
.sameLine {
  display: flex;
  justify-items: center;
  width: 100x;
  height: 170px;
  margin-left: 10px;
}
.sameLine img {
  width: 50px;
  height: 50px;
}
.sameLine p {
  padding: 15px;
  margin-bottom: 0px;
  color: black;
  display: block;
  font-size: 13px;
  font-weight: 600;
}
.sameLine button:focus {
  outline: 0;
}
.ButtonSelected {
  background-color: #ffba00;
}
.inputBox {
  width: 100%;
  border-style: none;
  border: 1px solid grey;
  margin: 5px;
  border-radius: 20px;
  padding: 5px;
}

/*.perMealDeal {
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
}*/

/*.proceedBtn {
  text-align: center;
  justify-content: center;
  padding: 5px !important;
  color: black !important;
  font-size: 16px !important;
  border: 1px solid rgb(187, 174, 174);
  border-radius: 10px;
  min-width: 100px !important;
  font-weight: bold;
  background-color: #ff6505;
  width: 300px;
}*/
.orangeBtn {
  text-align: center;
  justify-content: center;
  padding: 5px !important;
  /*color: black !important;*/
  color: #ffffff;
  font-size: 16px !important;
  /*border: 1px solid rgb(187, 174, 174);*/
  border-radius: 10px;
  border-width: 0;
  min-width: 100px !important;
  font-weight: bold;
  background-color: #ff6505;
  margin-top: 20px;
  width: 250px;
}
.chargeBtn {
  text-align: center;
  justify-content: center;
  padding: 5px !important;
  /*color: black !important;*/
  color: #ffffff;
  font-size: 16px !important;
  /*border: 1px solid rgb(187, 174, 174);*/
  border-radius: 10px;
  border-width: 0;
  min-width: 100px !important;
  background-color: #ff6505;
  margin-top: 10px;
  width: 90%;
  height: 50px;
}

.proceedBtn {
  text-align: center;
  justify-content: center;
  padding: 5px !important;
  /*color: black !important;*/
  color: #ffffff;
  font-size: 16px !important;
  /*border: 1px solid rgb(187, 174, 174);*/
  border-radius: 10px;
  min-width: 100px !important;
  font-weight: bold;
  background-color: #ff6505;
  margin-top: 20px;
  width: 60%;
}
.proceedWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 25px;
  margin-top: 15px;
}
/*.proceedWrapper {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 350px;
  padding-bottom: 100px;
}*/

.totalBtn,
.finishBtn {
  text-align: center;
  padding: 5px !important;
  margin: 10px;
  color: black !important;
  font-size: 16px !important;
  border: 1px solid rgb(187, 174, 174);
  border-radius: 10px;
  min-width: 100px !important;
  font-weight: bold;
  background-color: #ffba0038;
}
.finishBtn {
  box-shadow: 4px 1px #999;
}
.finishBtn:active {
  transform: translateX(2px);
  box-shadow: 0px 0px #666;
}
.finishBtn:focus {
  outline: 0;
  background-color: #ff9e19;
}

.disabledBtn {
  border: 1px solid #ccc9c9;
  background-color: #dfdede;
}
.disabledBtn p {
  color: #696969;
}
/*@media only screen and (max-width: 820px) {*/
  /*.full_screen {
    display: none !important;
  }*/
  .mobile {
    display: flex !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .root {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
  }

  .mealHeader {
    background-color: #ff9e19;
    border-radius: 0px 0px 30px 30px;
    display: flex;
    flex-direction: row;
    background-color: #ff9e19;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: black;
    box-sizing: border-box;
    height: 6rem;
    padding: 1rem;
    align-items: center;
    width: 100%;
  }

  .sectionHeader {
    background-color: #ffba00;
    width: 100%;
    height: 60px;
    font-weight: bold;
    padding-left: 8%;
    padding-top: 12px;
    font-size: 25px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .subsectionHeader {
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 60px;
  }

  .sectionHeaderScroll {
    background-color: #ffba00;
    width: 100%;
    height: 60px;
    font-weight: bold;
    padding-left: 100px;
    padding-top: 12px;
    font-size: 25px;
  }

  .sectionHeaderUL {
    background-color: #ffba00;
    width: 100%;
    height: 60px;
    font-weight: bold;
    padding-left: 100px;
    padding-top: 12px;
    margin-top: 30px;
    text-decoration: underline;
    font-size: 25px;
  }

  .headerItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .headerItem {
    flex: 1;
  }

  .headerItem:nth-of-type(1) {
    flex: 3;
  }

  .mealSelectMenu {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding: 0px 20px;
  }

  .mealDays {
    font-weight: bold;
    color: black;
    font-size: 15px;
    margin: 0px;
  }

  .subTitle {
    font-weight: 600;
    color: black;
    font-size: 1.2rem;
    margin: 1.3rem 0rem;
  }

  .mealNumber {
    font-weight: bold;
    color: black;
    font-size: 15px;
    margin: 1rem 0rem;
    text-align: center;
  }

  .buttonWrapper {
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  /*.mealButton {
    color: #000000;
    border: 2px solid #fff0c6;
    background-color: #fff0c6;
    border-radius: 25px;
    padding: 1rem 2rem;
    margin: 10px;
    align-content: center;
    flex: 1;
    min-width: 20%;
    font-size: 1rem;
    font-weight: 600;
  }*/

  /*.mealButtonSelected {
    background-color: #ffba00;
  }*/
  button:focus {
    outline: 0 !important;
  }
  .subTitle2 {
    font-weight: 600;
    color: black;
    font-size: 17px;
    margin: 0.5rem 0rem;
  }

  .paymentWrapper {
    min-width: 20%;
    border-radius: 70px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #000000;
    padding: 1.3rem 2rem;
    margin: 10px;
    align-content: center;
    flex: 1;
    min-width: 20%;
    font-size: 1rem;
    font-weight: 600;
    box-shadow: 1px 1px 1px 2px #d3d3d3;
    margin-top: 1.2rem;
    margin-bottom: 2.5rem;
  }

  .paymentButton {
    color: #5a5a5a;
    border: none;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin: 10px;
    padding: 2px;
  }

  .paymentButtonSelected {
    background-color: #ffba00;
  }

  .amount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.2rem;
    padding: 0rem 1.5rem;
    box-sizing: border-box;
  }
  .amountItem {
    border: none;
    width: 130px;
    height: 52px;
    border-radius: 32px;
    padding: 10px 10px;
  }
/*}*/

.googleMap {
  width: 903px;
  height: 255px;
  border-radius: 3px;
  border: solid;
  border-width: 1px;
  background-color: #F0F0F0;
  /* margin: 0px 0px 15px 20px; */
  vertical-align: middle;
}

.inputAmbassador {
  width: 100%;
  height: 2.2rem;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  padding: 6px 0px 6px 10px;
}
.inputAmbassador::placeholder {
  color: #ff6505;
}


.iconDeliveries {
  height: 78px;
  width: 78px;
  border: solid;
}

.chargeModalPopUpHide {
  z-index: 1;
  display: none;
}

.chargeModalPopUpShow {
  height: 100%;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  position: fixed;
  display: grid;
  background-color: rgba(255, 255, 255, 0.5);
}

.chargeModalContainer {
  position: relative;
  justify-self: center;
  align-self: center;
  display: block;
  border:#ff6505 solid;
  background-color: white;
  height: 350px;
  width: 450px;
  z-index: 2;
}

.chargeModalButtonMenu {
  text-decoration: none;
  color: black;
  font-weight: 450;
  height: fit-content;
  text-align: center;
  margin-left: 50px;
  margin-top: 54px;
  font-size: 18px;
  cursor: pointer;
}

.chargeModalButtonMenu:hover{
  text-decoration: none;
  color: #FF9400;
}

.chargeContainer {
  display: block;
  width: 370px;
  /*margin: 80px auto 0px;*/
  margin: 0px auto 0px;
  text-align: center;
}
