body {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
    var(--unnamed-line-spacing-21) var(--unnamed-font-family-sf-pro-display);
}

.root {
  background-color: #f26522;
  height: 93vh;
  min-width: 1150px;
  padding: 25px;
  overflow-x: auto;
}

.container {
  height: 100%;
}

.section {
  background-color: white;
  border-radius: 15px;
}

.row1 {
  height: 15%;
}

.row2 {
  margin-top: 10px;
  height: 85%;
}

.restaurantImg {
  height: 70px;
  width: 70px;
}

.restaurantSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown {
  border: none;
  padding: 0px;
  padding-right: 20px;
  color: black;
  background-color: white;
  width: 13vw;
  outline: none;
  display: inline-block;
  appearance: none;
  cursor: pointer;
  background: url("../../../images/dropdown.svg") no-repeat;
  background-position: right 5px top 50%;
}

.restaurantLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: #f26522;
  font-size: 12px;
  text-decoration: underline;
}

.dateSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.datebutton {
  height: 70px;
  width: 70px;
  font-size: 15px;
  border: none;
}

.datebuttonNotSelected {
  box-shadow: 0 0 7px #838383;
  background-color: white;
}

.datebuttonSelected {
  background-color: #f8bb17;
  border: 4px solid rgb(240, 142, 31);
}

.dateCarouselArrowBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.mealImg {
  height: 40px;
  width: 40px;
}

.verticallyCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.carouselSlider {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bold {
  font-weight: bold;
}

.scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.tableContainer {
  max-height: 80%;
}

.carouselCol {
  width: 700px;
}

@media only screen and (max-width: 1330px) {
  .contactInfo {
    display: none;
  }
}

@media screen and (max-width: 1639px) {
  .carouselCol {
    width: 550px;
  }
}

@media screen and (max-width: 1539px) {
  .carouselCol {
    width: 450px;
  }
}
