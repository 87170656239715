
.mobile {
  display: none !important;
}
.container {
  text-align: center;
}
.containerSplit {
  text-align: center;
  display: flex;
  /* border: 1px solid red; */
}

.menuSection {
  margin: 50px 0px 0px 0px;
  /*border: groove;*/
}

.input {
  width: 100%;
  height: 2.2rem;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  margin: 0px 0px 15px 0px;
  padding: 6px 0px 6px 10px;
}
.input:focus {
  width: 100%;
  height: 2.2rem;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  margin: 0px 0px 15px 0px;
  padding: 6px 0px 6px 10px;
  outline: 0;
}

/* .input {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  margin-bottom: 15px;
  margin-right: 2%;
  padding: 6px 0px 6px 10px;
}
.input:focus {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  margin-bottom: 15px;
  margin-right: 2%;
  padding: 6px 0px 6px 10px;
  outline: 0;
} */

.inputContactLeft {
  width: 48%;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  /* border-color: #ff6505; */
  margin-bottom: 15px;
  margin-right: 2%;
  padding: 6px 0px 6px 10px;
}

.inputContactRight {
  width: 48%;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  /* border-color: #ff6505; */
  margin-left: 2%;
  padding: 6px 0px 6px 10px;
}

.inputContactLeft:focus {
  width: 48%;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  /* border-color: #ff6505; */
  margin-bottom: 15px;
  margin-right: 2%;
  padding: 6px 0px 6px 10px;
  outline: 0;
}

.inputContactRight:focus {
  width: 48%;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  /* border-color: #ff6505; */
  margin-left: 2%;
  padding: 6px 0px 6px 10px;
  outline: 0;
}

.box {
  margin-left: 8%;
}


.boxPDleft {
  /*border: solid;
  border-color: red;*/
  width: 22%;
  margin-left: 8%;
  justify-content: center;
  /* border: 1px solid lime; */
}
.boxPDright {
  text-align: left;
  /*border: solid;
  border-color: red;*/
  margin-right: 8%;
  width: 62%;
  /* border: 1px solid cyan; */
}
.boxPDnarrowTop {
  width: 84%;
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 50px;
  justify-content: center;
  /* border: 1px solid blue; */
}
.boxPDnarrowBottom {
  width: 84%;
  margin-left: 8%;
  margin-right: 8%;
  justify-content: center;
  /* border: 1px solid blue; */
}
.box2 {
  display: flex;
  padding: 0px;
  background-color: transparent;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 30px;
}

.boxRight {
  width: 50%;
  padding-left: 8%;
  display: flex;
  margin-top: 30px;
  overflow: auto;
}

.boxScroll {
  margin-left: 4%;
  text-align: center;
  overflow-y: auto;
  width: 92%;
  flex: inline-block;
  height: 300px;
  /* border: 1px dashed; */
}

.forty {
  width: 40px;
  height: 40px;
  margin: 5px;
}
.bold {
  padding: 10px 10px 10px 0px;
  font-size: 15px;
  font-weight: bold;
  color: black;
}
.subHeader {
  padding: 10px 10px 10px 0px;
  font-size: 20px;
  font-weight: 500;
  color: black;
}
.center {
  display: block;
  margin: auto;
}

.plateButtonWrapper {
  display: inline-block;
  margin-right: 35px;
  margin-bottom: 50px;
  width: 120px;
  height: 120px;
}

.plateButton {
  color: #ffffff;
  border: 1px solid #e4e4e4;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 0.75rem 0.75rem;
  margin-top: 15px;
  align-content: center;
  flex: 1;
  min-width: 20%;
  font-size: 30px;
  font-weight: 600;
  outline: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('./static/yellow_plate.png'); */
  background-image: url('../../images/yellow_plate.png');
  background-size: 100%;
}
.plateButtonSelected {
  /* background-image: url('./static/orange_plate.png'); */
  background-image: url('../../images/orange_plate.png');
  background-color: #FFBA00;
  outline: 0;
}
.plateButtonSelected:focus {
  outline: 0 !important;
}
.plateButtonSelected:active {
  outline: 0 !important;
}

.activePlans {
  display: flex;
  /* border: 1px solid red; */
  min-width: 1232px;
}
/* 1232 */
/* @media (max-width: 1340px) {

} */

.mealButtonWrapper {
  margin-right: 35px;
  margin-bottom: 50px;
  width: 120px;
  height: 120px;
}

.mealButtonHeader {
  height: 50px;
  border-bottom: 2px solid #ffba00;
  min-width: 1232px;
}

.mealButton {
  border-bottom: 2px solid #ffba00;
  background-color: #ffffff;
  border-radius: 0px;
  padding: 0 0 0 0;
  width: 100%;
  font-size: 20px;
  outline: 0;
  height: 50px;
  background-size: 100%;
  cursor: pointer;
}

.mealButtonSelected {
  background-color: #ffba00;
  outline: 0;
}
.mealButtonSelected:hover {
  background-color: #ffba00;
  outline: 0;
}

.mealButtonSelected:focus {
  outline: 0 !important;
}
.mealButtonSelected:active {
  outline: 0 !important;
}

.mealButtonEdit {
  width: 4%;
  height: 100%;
  float: left;
  padding-top: 8px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.mealButtonPlan {
  width: 16%;
  height: 100%;
  float: left;
  padding-top: 8px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.mealButtonSection {
  width: 16%;
  height: 100%;
  float: left;
  padding-top: 8px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}

.deliverySubtext {
  color: #000000;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 2px #d3d3d3;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  margin: 5px;
  text-align: center;
  flex: 1;
  min-width: 20%;
  font-size: 13px;
  font-weight: 600;
  outline: 0;
}

/*.deliveryButton {
  color: #000000;
  background-color: #ffffff;
  margin: 5px;
  align-content: center;
  flex: 1;
  min-width: 20%;
  font-size: 10px;
  font-weight: 600;
  outline: 0;
  height: 78px;
  width: 78px;
  border: solid;
  border-color: #ff6505;
  box-shadow: none !important;
  outline: 0;
  border-radius: 0;
}*/
.deliveryButton {
  color: #000000;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 2px #d3d3d3;
  background-color: #ffffff;
  /*border-radius: 25px;*/
  padding: 0.5rem 0.5rem;
  margin: 5px;
  align-content: center;
  flex: 1;
  min-width: 20%;
  font-size: 13px;
  font-weight: 600;
  outline: 0;
  height: 100px;
  width: 100px;
  border-radius: 0;
}

.checkbox {
  height: 20px;
  width: 20px;
}
/*.checkboxContainer {
  border: solid;
}*/
.checkboxLabel {
  margin-right: 15px;
  font-size: 20px;
}

/*.deliveryButtonSelected {
  background-color: #FFBA00;
  outline: 0;
}*/
/*.deliveryButtonSelected {
  border: solid;
  border-color: #ff6505;
  box-shadow: none !important;
  outline: 0;
}*/
.deliveryButtonCurrent {
  color: #000000;
  background-color: #ffffff;
  margin: 5px;
  align-content: center;
  flex: 1;
  min-width: 20%;
  font-size: 10px;
  font-weight: 600;
  outline: 0;
  height: 130px;
  width: 130px;
  border: solid;
  border-color: #ff6505;
  box-shadow: none !important;
  outline: 0;
  border-radius: 0;
}
.deliveryButton:disabled {
  color: #000000;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 2px #d3d3d3;
  opacity: 0.5;
  /* background-color: #ccc9c9; */
  /*border-radius: 25px;*/
  margin: 5px;
  align-content: center;
  flex: 1;
  min-width: 20%;
  font-size: 10px;
  font-weight: 600;
  outline: 0;
  height: 78px;
  width: 78px;
}

.deliveryButtonSelected {
  /*background-color: #FFBA00;*/
  border: solid;
  border-color: #ff6505;
  box-shadow: none !important;
  outline: 0;
}
.deliveryButtonSelected:focus {
  outline: 0 !important;
}
.deliveryButtonSelected:active {
  outline: 0 !important;
}
.sameLine {
  display: flex;
  justify-items: center;
  width: 100x;
  height: 170px;
  margin-left: 10px;
}
.sameLine img {
  width: 50px;
  height: 50px;
}
.sameLine p {
  padding: 15px;
  margin-bottom: 0px;
  color: black;
  display: block;
  font-size: 13px;
  font-weight: 600;
}
.sameLine button:focus {
  outline: 0;
}
.ButtonSelected {
  background-color: #ffba00;
}
.inputBox {
  /*width: 100%;*/
  width: 42%;
  border-style: none;
  border: 1px solid grey;
  margin: 5px;
  border-radius: 20px;
  padding: 5px;
}

/*.saveBtn {
  margin-top: 36px;
  margin-bottom: 50px;
  background-color: #f26522;
  color: white;
  font-size: 20px;
  border-radius: 15px;
  border: none;
  height: 54px;
  width: 70%;
}
.saveBtn:disabled {
  margin-top: 36px;
  margin-bottom: 50px;
  background-color: #ccc9c9;
  color: white;
  font-size: 20px;
  border-radius: 15px;
  border: none;
  height: 54px;
  width: 70%;
}*/
.orangeBtn {
  margin-top: 36px;
  margin-bottom: 50px;
  background-color: #f26522;
  color: white;
  font-size: 20px;
  border-radius: 15px;
  border: none;
  height: 54px;
  width: 70%;
}
.orangeBtn:hover:enabled {
  background-color: #ffba00;
}
.orangeBtn:disabled {
  opacity: 0.5;
}

.orangeBtn2 {
  margin-top: 50px;
  margin-bottom: 10px;
  background-color: #f26522;
  color: white;
  font-size: 20px;
  border-radius: 15px;
  border: none;
  height: 54px;
  width: 70%;
}
.orangeBtn2:hover:enabled {
  background-color: #ffba00;
}
.orangeBtn2:disabled {
  opacity: 0.5;
}

.orangeBtn3 {
  margin-bottom: 50px;
  background-color: #f26522;
  color: white;
  font-size: 20px;
  border-radius: 15px;
  border: none;
  height: 54px;
  width: 70%;
}
.orangeBtn3:hover:enabled {
  background-color: #ffba00;
}
.orangeBtn3:disabled {
  opacity: 0.5;
}

.proceedBtn {
  text-align: center;
  justify-content: center;
  padding: 5px !important;
  /*color: black !important;*/
  color: #ffffff;
  font-size: 16px !important;
  /*border: 1px solid rgb(187, 174, 174);*/
  border-radius: 10px;
  min-width: 100px !important;
  font-weight: bold;
  background-color: #ff6505;
  margin-top: 20px;
  width: 60%;
}
.proceedBtn:disabled {
  text-align: center;
  justify-content: center;
  padding: 5px !important;
  /*color: black !important;*/
  color: #696969;
  font-size: 16px !important;
  /*border: 1px solid rgb(187, 174, 174);*/
  border-radius: 10px;
  min-width: 100px !important;
  font-weight: bold;
  background-color: #ff6505;
  margin-top: 20px;
  width: 60%;
}
.proceedWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 25px;
  margin-top: 15px;
}

.totalBtn,
.finishBtn {
  text-align: center;
  padding: 5px !important;
  margin: 10px;
  color: black !important;
  font-size: 16px !important;
  border: 1px solid rgb(187, 174, 174);
  border-radius: 10px;
  min-width: 100px !important;
  font-weight: bold;
  background-color: #ffba0038;
}
.finishBtn {
  box-shadow: 4px 1px #999;
}
.finishBtn:active {
  transform: translateX(2px);
  box-shadow: 0px 0px #666;
}
.finishBtn:focus {
  outline: 0;
  background-color: #ff9e19;
}

.disabledBtn {
  border: 1px solid #ccc9c9;
  background-color: #dfdede;
}
.disabledBtn p {
  color: #696969;
}
/*@media only screen and (max-width: 820px) {*/
  /*.full_screen {
    display: none !important;
  }*/
  .mobile {
    display: flex !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .root {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
  }

  .mealHeader {
    background-color: #ff9e19;
    border-radius: 0px 0px 30px 30px;
    display: flex;
    flex-direction: row;
    background-color: #ff9e19;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: black;
    box-sizing: border-box;
    height: 6rem;
    padding: 1rem;
    align-items: center;
    width: 100%;
  }

  .planHeader{
    font-size: 25px; 
    font-weight: bold;
    margin-bottom: 30px;
  }

  .sectionHeader {
    background-color: #ffba00;
    width: 100%;
    height: 60px;
    font-weight: bold;
    /*padding-left: 100px;*/
    padding-left: 8%;
    /* padding-top: 12px; */
    display: flex;
    align-items: center;
    font-size: 25px;
    margin-bottom: 30px;
    text-align: left;
  }
  .sectionHeaderLeft {
    background-color: #ffba00;
    width: 48%;
    height: 60px;
    font-weight: bold;
    /*padding-left: 100px;*/
    padding-left: 8%;
    /* padding-top: 12px; */
    display: flex;
    align-items: center;
    font-size: 25px;
    margin-bottom: 30px;
    margin-right: 2%;
  }
  .sectionHeaderRight {
    background-color: #ffba00;
    width: 48%;
    height: 60px;
    font-weight: bold;
    /*padding-left: 100px;*/
    padding-left: 2%;
    /* padding-top: 12px; */
    display: flex;
    align-items: center;
    font-size: 25px;
    margin-bottom: 30px;
    margin-left: 2%;
  }

  .sectionHeaderScroll {
    background-color: #ffba00;
    width: 100%;
    height: 60px;
    font-weight: bold;
    /*padding-left: 100px;*/
    padding-left: 8%;
    /* padding-top: 12px; */
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-size: 25px;
  }

  .sectionHeaderUL {
    background-color: #ffba00;
    width: 100%;
    height: 60px;
    font-weight: bold;
    /*padding-left: 100px;*/
    padding-left: 8%;
    /* padding-top: 12px; */
    display: flex;
    align-items: center;
    font-size: 25px;
    margin-bottom: 30px;
    /* text-decoration: underline; */
  }
  .sectionHeaderAdmin {
    background-color: #ffba00;
    width: 50%;
    height: 60px;
    font-weight: bold;
    /*padding-left: 100px;*/
    padding-left: 8%;
    padding-top: 12px;
    font-size: 25px;
    margin-bottom: 30px;
    /* text-decoration: underline; */
  }

  .headerItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .headerItem {
    flex: 1;
  }

  .headerItem:nth-of-type(1) {
    flex: 3;
  }

  .mealSelectMenu {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding: 0px 20px;
  }

  .mealDays {
    font-weight: bold;
    color: black;
    font-size: 15px;
    margin: 0px;
  }

  .subTitle {
    font-weight: 600;
    color: black;
    font-size: 1.2rem;
    margin: 1.3rem 0rem;
  }

  .mealNumber {
    font-weight: bold;
    color: black;
    font-size: 15px;
    margin: 1rem 0rem;
    text-align: center;
  }

  .buttonWrapper {
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  button:focus {
    outline: 0 !important;
  }
  .subTitle2 {
    font-weight: 600;
    color: black;
    font-size: 17px;
    margin: 0.5rem 0rem;
  }

  .paymentWrapper {
    min-width: 20%;
    border-radius: 70px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #000000;
    padding: 1.3rem 2rem;
    margin: 10px;
    align-content: center;
    flex: 1;
    min-width: 20%;
    font-size: 1rem;
    font-weight: 600;
    box-shadow: 1px 1px 1px 2px #d3d3d3;
    margin-top: 1.2rem;
    margin-bottom: 2.5rem;
  }

  .paymentButtonSelected {
    background-color: #ffba00;
  }

  .amount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.2rem;
    padding: 0rem 1.5rem;
    box-sizing: border-box;
  }
  .amountItem {
    border: none;
    width: 130px;
    height: 52px;
    border-radius: 32px;
    padding: 10px 10px;
  }
/*}*/

.googleMap {
  width: 100%;
  height: 255px;
  border-radius: 3px;
  border: solid;
  border-width: 1px;
  background-color: #F0F0F0;
  vertical-align: middle;
  margin-top: 15px;
}
.googleHidden {
  width: 100px;
  height: 255px;
  border-radius: 3px;
  border: solid;
  border-width: 1px;
  background-color: #F0F0F0;
  vertical-align: middle;
  margin-top: 15px;
}

.inputAmbassador {
  width: 28%;
  height: 30px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  background-color: white;
  outline: none;
  padding: 6px 0px 6px 10px;
  margin-top: 17px;
  font-size: 12px;
  font-weight: 500;
}

.iconCard {
  background-image: url('../../images/card_icon.png');
  background-size: 100%;
  outline: 0;
  height: 78px;
  width: 100px;
}
.iconMeals {
  background-image: url('../../images/orange_plate.png');
  background-size: 100%;
  outline: 0;
  height: 130px;
  width: 130px;
  color: white;
  padding-top: 2.4rem;
  font-size: 35px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  margin: auto;
}
.iconMealsDisabled {
  background-image: url('../../images/yellow_plate.png');
  background-size: 100%;
  outline: 0;
  height: 80px;
  width: 80px;
  color: white;
  padding-top: 1.5rem;
  font-size: 20px;
  cursor: pointer;
}
.iconDeliveries {
  height: 78px;
  width: 78px;
  border: solid;
}
.iconDeliveries {
  height: 78px;
  width: 78px;
  border: solid;
}
.iconTrash {
  background-image: url('../../images/trash_icon.png');
  background-size: 100%;
  border: none;
  background-color: rgba(255, 255, 255, 0.0);
  outline: 0;
  margin: auto;
  height: 65px;
  width: 50px;
  cursor: pointer;
}
.iconTrash:disabled {
  /* background-image: none; */
  background-image: url('../../images/trash_icon.png');
  /* color:rgba(255, 255, 255, 0.5); */
  opacity: 0.5;
  background-size: 100%;
  background-color: rgba(255, 255, 255, 0.0);
  outline: 0;
  margin: auto;
  height: 65px;
  width: 50px;
  cursor: default;
}

.summaryLeft {
  width: 34%;
  text-align: left;
  margin: 10px 0px 10px 0px;
  /*border: solid;
  border-color: red;*/
}

.cardDiv {
  margin-top: 15px;
  display: flex;
}
.cardLabel {
  margin-top: 15px;
  display: flex;
}

.inputCard {
  height: 40px;
  width: 40%;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  align-items: left;
  padding: 6px 0px 6px 10px;
  outline: 0;
}
.inputCard:focus {
  height: 40px;
  width: 40%;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  align-items: left;
  padding: 6px 0px 6px 10px;
  outline: 0;
}
.inputCardDate {
  width: 15%;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  align-items: left;
  padding: 6px 0px 6px 10px;
  outline: 0;
  margin-left: 2%;
}
.inputCardDate:focus {
  width: 15%;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  align-items: left;
  padding: 6px 0px 6px 10px;
  outline: 0;
  margin-left: 2%;
}
.inputCardCvv {
  width: 15%;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  align-items: left;
  padding: 6px 0px 6px 10px;
  outline: 0;
  margin-left: 2%;
}
.inputCardCvv:focus {
  width: 15%;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  align-items: left;
  padding: 6px 0px 6px 10px;
  outline: 0;
  margin-left: 2%;
}
.inputCardZip {
  width: 24%;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  align-items: left;
  padding: 6px 0px 6px 10px;
  outline: 0;
  margin-left: 2%;
}
.inputCardZip:focus {
  width: 24%;
  height: 40px;
  border-radius: 10px;
  border: solid;
  border-width: 2px;
  border-color: #ff6505;
  align-items: left;
  padding: 6px 0px 6px 10px;
  outline: 0;
  margin-left: 2%;
}

.summaryRight {
  width: 22%;
  text-align: right;
  margin-top:10px;
  margin-bottom: 10px;
  font-weight: bold;
}
.summaryGray {
  width: 22%;
  text-align: right;
  margin-top:10px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #9c9c9c;
}
.summarySubtotal {
  width: 22%;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
.summarySubGray {
  width: 22%;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #9c9c9c;
}
.summarySubLeft {
  width: 14%;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
.summarySubLeftGray {
  width: 14%;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #9c9c9c;
}
.summaryUpdated {
  width: 19%;
  text-align: right;
  margin-top:10px;
  margin-bottom: 10px;
  padding-right: 30px;
  font-weight: bold;
  border: solid;
  border-color: green;
}
.summaryCurrent {
  width: 19%;
  text-align: right;
  margin-top:10px;
  margin-bottom: 10px;
  padding-right: 30px;
  font-weight: bold;
  border: solid;
  border-color: orange;
}
.summaryDifference {
  width: 19%;
  text-align: right;
  margin-top:10px;
  margin-bottom: 10px;
  padding-right: 30px;
  font-weight: bold;
  border: solid;
  border-color: blue;
}

.tipButton {
  border: solid;
  border-color: #ff6505;
  background-color: white;
  border-width: 1px;
  height: 30px;
  width: 63px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10%;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 5px;
}
.tipButton:hover:enabled {
  background-color: #ffba00;
  color: white;
}
.tipButton:disabled {
  opacity: 0.5;
}

.tipButtonSelected {
  border: solid;
  border-color: #ff6505;
  background-color: #ff6505;
  color: #ffffff;
  border-width: 1px;
  height: 30px;
  width: 63px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10%;
  margin-bottom: 10px;
  border-radius: 5px;
}
.tipButtonSelected:disabled {
  border: solid;
  border-color: #ff6505;
  background-color: #ff6505;
  color: #ffffff;
  border-width: 1px;
  height: 30px;
  width: 63px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10%;
  margin-bottom: 10px;
  border-radius: 5px;
}
.tipButtonSelected:hover {
  border: solid;
  border-color: #ff6505;
  background-color: #ff6505;
  color: #ffffff;
  border-width: 1px;
  height: 30px;
  width: 63px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10%;
  margin-bottom: 10px;
  border-radius: 5px;
}

/*.inputAmbassador {
  width: 200px;
  height: 43px;
  border-radius: 15px;
  border: solid;
  border-width: 1px;
  background-color: white;
  margin-top: 17px;
}*/

/* .codeButton {
  width: 126px;
  height: 43px;
  border: none;
  background-color: #ff6505;
  border-radius: 15px;
  border-width: 2px;
  margin-left: 5px;
  margin-top: 17px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 500;
  color:white
}
.codeButton:hover {
  width: 126px;
  height: 43px;
  border: none;
  background-color: #ff6505;
  border-radius: 15px;
  border-width: 2px;
  margin-left: 5px;
  margin-top: 17px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 500;
  color:white
} */

.codeButton {
  width: 12%;
  height: 30px;
  border: none;
  background-color: #ff6505;
  border-radius: 10px;
  border-width: 2px;
  margin-left: 2%;
  margin-top: 17px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 500;
  color:white
}
.codeButton:hover:enabled {
  background-color: #ffba00;
}
.codeButton:disabled {
  opacity: 0.5;
}

.chargeTotal {
  border: solid;
  border-radius: 25px;
  border-color: #ff6505;
  height: 80px;
  justify-content: center;
}
.chargeText {
  font-size: 20px;
  padding-top: 20px;
}
.chargeAmount {
  font-size: 40px;
  font-weight: bold;
  padding-top: 5px;
  padding-left: 20px;
}
.chargeBtn {
  text-align: center;
  justify-content: center;
  padding: 5px !important;
  /*color: black !important;*/
  color: #ffffff;
  font-size: 16px !important;
  /*border: 1px solid rgb(187, 174, 174);*/
  border-radius: 10px;
  border-width: 0;
  min-width: 100px !important;
  background-color: #ff6505;
  margin-top: 10px;
  width: 90%;
  height: 50px;
}
.chargeBtn:hover {
  background-color: #ffba00;
}
.chargeModalContainer {
  position: relative;
  justify-self: center;
  align-self: center;
  display: block;
  border:#ff6505 solid;
  background-color: white;
  height: 350px;
  width: 450px;
  z-index: 2;
}
.chargeContainer {
  display: block;
  width: 370px;
  /*margin: 80px auto 0px;*/
  margin: 0px auto 50px;
  text-align: center;
  /*border: inset;*/
  float: left;
}
.chargeContainerNarrow {
  display: block;
  width: 370px;
  /*margin: 80px auto 0px;*/
  margin: 0px auto 50px;
  text-align: center;
  /*border: inset;*/
  float: center;
}

.chargeCancelButton{
  background-image: url('../../images/x_button.png');
  z-index: 2; 
  float: right;
  position: absolute; 
  top: 0px;
  left: 370px;
  background-size: 100%;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  cursor: pointer;
}

/*--------- pop up stuf ---*/
.errorTotal {
  display: flex;
  border: solid;
  border-radius: 25px;
  border-color: #ff6505;
  height: 80px;
  justify-content: center;
}
.errorText {
  font-size: 20px;
  padding-top: 20px;
}
.errorAmount {
  font-size: 40px;
  font-weight: bold;
  padding-top: 5px;
  padding-left: 20px;
}
.errorHeader {
  font-size: 40px;
  font-weight: bold;
  padding-top: 5px;
  padding-left: 20px;
  margin-top: 50px;
}

.errorModalPopUpHide {
  z-index: 1;
  display: none;
}

.errorModalPopUpShow {
  height: 100%;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  position: fixed;
  display: grid;
  background-color: rgba(255, 255, 255, 0.5);
}

.errorModalContainer {
  position: relative;
  justify-self: center;
  align-self: center;
  display: block;
  border:#ff6505 solid;
  background-color: white;
  /* height: 350px; */
  padding-bottom: 60px;
  width: 450px;
  z-index: 2;
}

.errorModalButtonMenu {
  text-decoration: none;
  color: black;
  font-weight: 450;
  height: fit-content;
  text-align: center;
  margin-left: 50px;
  margin-top: 54px;
  font-size: 18px;
  cursor: pointer;
}

.errorModalButtonMenu:hover{
  text-decoration: none;
  color: #FF9400;
}

.errorContainer {
  display: block;
  width: 370px;
  /*margin: 80px auto 0px;*/
  margin: 0px auto 0px;
  text-align: center;
}

.errorCancelButton{
  background-image: url('../../images/x_button.png');
  z-index: 2; 
  float: right;
  position: absolute; 
  top: 0px;
  left: 370px;
  background-size: 100%;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  cursor: pointer;
}

.confirmModalContainer {
  position: relative;
  justify-self: center;
  align-self: center;
  display: block;
  border: #ff6505 solid;
  background-color: white;
  /* height: 350px; */
  padding-bottom: 60px;
  width: 450px;
  z-index: 2;
}

.confirmContainer {
  display: block;
  width: 400px;
  /*margin: 80px auto 0px;*/
  margin: 0px auto 0px;
  text-align: center;
}

.confirmHeader {
  font-size: 40px;
  font-weight: bold;
  padding-top: 5px;
  /* padding-left: 20px; */
  margin-top: 35px;
  /* border: solid; */
}

.confirmBtn {
  /* text-align: center;
  justify-content: center; */
  /* padding: 5px !important; */
  /*color: black !important;*/
  color: #ffffff;
  font-size: 16px !important;
  /*border: 1px solid rgb(187, 174, 174);*/
  border-radius: 10px;
  border-width: 0;
  min-width: 100px !important;
  background-color: #ff6505;
  margin-top: 10px;
  /* width: 90%; */
  margin-right: 15px;
  margin-left: 15px;
  width: 30%;
  height: 50px;
}
.confirmBtn:hover {
  background-color: #ffba00;
}

.deletingContainer {
  display: block;
  width: 400px;
  /*margin: 80px auto 0px;*/
  margin: 0px auto 0px;
  text-align: center;
}

.deletingHeader {
  font-size: 40px;
  font-weight: bold;
  padding-top: 5px;
  /* align-items: center; */
  /* padding-left: 20px; */
  margin-top: 100px;
  /* border: solid; */
}

.cancelledHeader {
  font-size: 40px;
  font-weight: bold;
  padding-top: 5px;
  /* align-items: center; */
  /* padding-left: 20px; */
  margin-top: 30px;
  /* border: solid; */
}

.cancelledBtn {
  text-align: center;
  justify-content: center;
  padding: 5px !important;
  /*color: black !important;*/
  color: #ffffff;
  font-size: 16px !important;
  /*border: 1px solid rgb(187, 174, 174);*/
  border-radius: 10px;
  border-width: 0;
  min-width: 100px !important;
  background-color: #ff6505;
  margin-top: 30px;
  width: 90%;
  height: 50px;
}
.cancelledBtn:hover {
  background-color: #ffba00;
}