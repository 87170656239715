.containerCustomer {
    /* text-align: center;
    display: flex; */
    width: 96%;
    height: 120px;
    z-index: 1;
    /* border: solid; */
    border-radius: 15px;
    margin-top: 20px;
    margin-left: 2%;
    margin-right: 2%;
    background-color: white;
    /* display: flex; */
}

.containerMeals {
    /* text-align: center; */
    /* display: inline-block; */
    /* display: flex; */
    width: 96%;
    height: 750px;
    /* border: solid; */
    border-radius: 15px;
    margin-top: 20px;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 20px;
    background-color: white;
    z-index: -500;
}

.dropdown {
  border: none;
  padding: 0px;
  padding-right: 20px;
  color: black;
  background-color: white;
  width: 25vw;
  outline: none;
  display: inline-block;
  appearance: none;
  cursor: pointer;
  background: url("../../../images/dropdown.svg") no-repeat;
  background-position: right 5px top 50%;
}

.googleMap {
    width: 98%;
    height: 725px;
    border-radius: 3px;
    border: solid;
    border-width: 1px;
    background-color: #F0F0F0;
    vertical-align: middle;
    margin: 1%;
}

.spacer {
    width: 100%; 
    float: left; 
    height: 30px;
}

.spacerSmall {
    width: 100%; 
    float: left; 
    height: 10px;
}