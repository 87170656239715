/* .poploginsignup {
  position: absolute;
  background-color: white;
  z-index: 1;
  right: 0%;
  top: -800px;
} */
.poploginsignup {
  /* position: absolute; */
  background-color: rgb(255,255,255,0.5);
  z-index: 3;
  /* top: 0px;
  left: 0px; */
  /* display */
  /* width: 100%;
  height: 100%; */
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
  /* align-items: center; */
  /* border: 1px solid blue; */
  /* right: 0%; */
  /* top: -800px; */
}

.becomeAnAmbassadorPopup {
  position: fixed;
  /* background-color: white; */
  z-index: 1;
  width: 600px;
  height: 600px;
  left: 30%;
  top: 20%;
  bottom: 10px;
  /* background-image: url("../../images/ambassadorNotLogin.png"); */
  background-color: white;
  background-size: 1600px 800px;
  background-position: center;
  background-repeat: no-repeat;
}

.becomeAnAmbassadorPopupSignin {
  /* position: fixed; */
  z-index: 1;
  width: 540px;
  height: "auto";
  /* max-height: 100%;
  overflow: auto; */
  /* left: 30%; */
  /* top: 20%; */
  /* bottom: 10px; */
  border: 2px solid #f26522;
  background-color: white;
  /* background-image: url("../../images/ambassadorLogin.png"); */
  /* background-size:540px 666px;
  background-position: center;
  background-repeat: no-repeat; */
}

.becomeAmbassContent {
  text-align: center;
  padding: 0px 50px 20px 50px;
  /* border: 1px solid blue; */
}

.ambNameWrapper {
  background-color: white;
  color: black;
  text-align: center;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 20px 20px 0px 20px; */
  /* margin-top: 30px; */
  margin: 50px 20px 0px 20px;
  /* border: 1px solid blue; */
}

.ambPopUpBtn {
  width: 410px;
  height: 75px;
  background-color: #F26522;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 24px;
}

.ambEmailInput {
  width: 385px;
  height: 42px;
  background-color: white;
  border: 2px solid #F26522;
  border-radius: 15px;
  outline: none;
  padding-left: 15px;
}

.ambContent2 {
  text-align: center;
  padding: 0px 50px 20px 50px;
  /* border: 1px dashed; */
}

@media (max-width: 550px) {
  .becomeAnAmbassadorPopupSignin {
    /* position: fixed; */
    z-index: 1;
    /* width: 540px; */
    width: 96%;
    height: "auto";
    max-height: 96%;
    /* max-height: 100%;
    overflow: auto; */
    /* left: 30%; */
    /* top: 20%; */
    /* bottom: 10px; */
    border: 2px solid #f26522;
    background-color: white;
    overflow-y: scroll;
    /* background-image: url("../../images/ambassadorLogin.png"); */
    /* background-size:540px 666px;
    background-position: center;
    background-repeat: no-repeat; */
  }

  .becomeAmbassContent {
    text-align: center;
    /* padding: 0px 50px 20px 50px; */
    padding: 0;
    /* border: 1px solid green; */
  }

  .ambNameWrapper {
    /* background-color: white;
    color: black;
    text-align: center;
    font-size: 18px; */
    /* display: flex; */
    /* flex-direction: row;
    justify-content: space-between; */
    /* padding: 20px 20px 0px 20px;
    margin-top: 30px; */
    display: inline-block;
    /* border: 1px solid lime; */
  }

  .ambPopUpBtn {
    width: 96%;
    /* width: 410px; */
    /* height: 75px;
    background-color: #F26522;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 24px; */
  }

  .ambContent2 {
    text-align: center;
    padding: 0;
    /* border: 1px dashed; */
    margin: 0px 10px 20px 10px;
  }

  .ambEmailInput {
    width: 96%;
    /* width: 385px; */
    /* height: 42px;
    background-color: white;
    border: 2px solid #F26522;
    border-radius: 15px;
    outline: none;
    padding-left: 15px; */
  }
}

.orangeBtn {
  width: 288px;
  height: 52px;
  background-color: #f26522;
  border-radius: 15px;
  color: white;
  font-size: 18px;
  border: none;
  margin: 40px 0px 10px 0px;
  /* border: 1px dashed; */
}
.orangeBtn:hover {
  width: 288px;
  height: 52px;
  background-color: #ffab00;
  border-radius: 15px;
  color: white;
  font-size: 18px;
  border: none;
  margin: 40px 0px 10px 0px;
}

.closeBtn {
  cursor: pointer;
}
.closeBtn:hover {
  opacity: 0.5;
}