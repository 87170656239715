.menu {
  width: 100%;
  display: flex;
  position: relative;
  height: 80px;
  /* border: 1px solid red; */
  margin-top: 40px;
}

.headerWrapper {
  /* border: 1px solid violet; */
  display: flex;
  width: 84%;
  margin-left: 8%;
}

.headerLeft {
  /* border: 1px dashed; */
  display: flex;
  align-items: center;
  height: 40px;
}

.dropdownSelection {
  color: white;
  border-radius: 15px;
  background-color: #f26522;
  font-size: 20px;
  height: 40px;
  /* padding-top: 5px; */
  box-shadow: 0px 5px 10px gray;
  /* margin-top: 40px; */
  /* margin-bottom: 10px; */
  display: flex;
  align-items: center;
  /* width: 40%;
  min-width: 300px; */
  /* min-width: 40%; */
  /* max-width: 350px; */
  width: 350px;
  z-index: 3;
  position: absolute;
  right: 8%;
  cursor: pointer;
  /* border: 1px solid orange; */
}
.dropdownStyling {
  background-color: #f26522;
  width: 350px;
  position: absolute;
  right: 8%;
  z-index: 1;
  box-shadow: 0px 5px 10px gray;
  border-radius: 15px;
}
.dropdownStyling2 {
  /* border: 1px inset; */
  position: static;
}

.dropdownWrapper {
  /* border: 1px solid blue; */
  border: none;
  /* right: 0px; */
}

@media (max-width: 800px) {
  .menu {
    width: 100%;
    display: flex;
    position: relative;
    height: 120px;
    /* border: 1px solid blue; */
    margin-top: 40px;
  }
  .headerWrapper {
    /* border: 1px solid lime; */
    display: inline-block;
    width: 100%;
    margin-left: 0%;
  }
  .headerLeft {
    /* border: 1px dashed; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-bottom: 12px;
    margin-left: 8%;
    margin-right: 8%;
  }
  .dropdownSelection {
    color: white;
    border-radius: 15px;
    background-color: #f26522;
    font-size: 20px;
    height: 40px;
    /* padding-top: 5px; */
    box-shadow: 0px 5px 10px gray;
    /* margin-top: 40px; */
    /* margin-bottom: 10px; */
    display: flex;
    align-items: center;
    /* width: 40%;
    min-width: 300px; */
    /* min-width: 40%; */
    /* max-width: 350px; */
    width: 350px;
    z-index: 3;
    position: relative;
    right: 0%;
    cursor: pointer;
    max-width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    /* border: 1px solid teal; */
  }
  .dropdownStyling {
    background-color: #f26522;
    width: 350px;
    position: absolute;
    /* right: 8%; */
    top: 20px;
    /* center: 0; */
    z-index: 1;
    box-shadow: 0px 5px 10px gray;
    border-radius: 15px;
    right: 0;
    /* border: 1px dashed; */
    /* max-width: 100%; */
  }
  .dropdownWrapper {
    /* border: 1px solid red; */
    position: relative;
    /* right: 0%; */
    /* bottom: 0px; */
    display: flex;
    justify-content: center;
  }
  .dropdownStyling2 {
    /* border: 1px dotted; */
    position: relative;
  }
}

.menu1 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-left:20px;
}

.logo {
  display: flex;
  width: 50%;
  flex-direction: row;
  margin-top: 1rem;
  padding: 10px;
  text-align: left;
}
.logo img {
  max-width: 40px;
  max-height: 40px;
  margin-left: 10px;
}
.logo > p {
  display: block;
  padding: 10px;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 14px;
}

.selectBtn {
  width: 50%;
  display: flex;
  margin-top: 1rem;
  padding: 1.5rem;
  justify-content: space-evenly;
  text-align: center;

  float: right;
}
.selectBtn a {
  text-decoration: none;
  padding: 10px;
  color: black;
  font-weight: 450;
  vertical-align: center;
}
.selectBtn a:hover {
  /* background-color: #7fa4dd; */
  color: #FF9400;
  border-radius: 20px;
  outline-offset: 5px;
}
.selectBtn a:active {
  transform: translateY(2px);
}
a {
  display: flex;
  justify-content: space-between;
}
a img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}
.w7 {
  width: 50%;
}
.selectBtn div img,
.selectBtn div p {
  display: inline;
  margin-bottom: 0px;
}
.selectBtn div img {
  width: 40px;
  height: 40px;
}
.selectBtn div p {
  padding: 10px;
  color: black;
  font-size: 14px;
  font-weight: bold;
}
.navMessage {
  /* width: 400px; */
  color: black !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  /* margin-bottom: 0px; */


  /* padding-left: 0px; */
  /* text-align: center; */
  /* margin-left: 120px; */
  /* margin-top: 79px; */
  
}

.subscribe {
  height: 40px;
  width: 450px;
  border-radius: 30px;
  background-color: #ff9e19;
  border: 1px solid black;
  position:relative;
  text-align: center;
} 

select.pickers {
  background-image:
    linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%);
  background-position:
    calc(100% - 30px) calc(15px),
    calc(100% - 15px) calc(15px),
    100% 0;
  background-size:
    15px 15px,
    15px 15px;
    /* 2.6em 2.6em */
  background-repeat: no-repeat;
}

.menuButton {
  border-radius: 10px;
  background-color: white;
  height: 32px;
  width: 96%;
  /* // paddingLeft: "10px", */
  margin-left: 2%;
  margin-top: 10px;
  text-overflow: ellipsis;
  /* // display: "block", */
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.menuButton:hover {
  border-radius: 10px;
  background-color: #ffba00;
  height: 32px;
  width: 96%;
  /* // paddingLeft: "10px", */
  margin-left: 2%;
  margin-top: 10px;
  text-overflow: ellipsis;
  /* // display: "block", */
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

select {
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;


  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -moz-appearance: none;

  text-align-last: left !important;
  -webkit-appearance: menulist;
  height: 40px;
  width: 350px;
  position: relative;

  border-radius: 20px;
  color: white;
  font-weight: 500;
  border: 1px solid #ffba00;

  font-size: 18px;

  background-color: #f26522;
}

.pickers:focus {
  outline: none;
}

.whiteArrowDown {
  background-image: url('../../images/white_arrow_down.png');
  background-size: 100%;
  width: 24px;
  height: 16px;
  margin-top: 6px;
  float: right;
}
