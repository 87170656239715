body {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
    var(--unnamed-line-spacing-21) var(--unnamed-font-family-sf-pro-display);
  width: 100vw;
}

.root {
  background-color: #f26522;
  height: 93vh;
  min-width: 1275px;
  padding: 25px;
  overflow-x: scroll;
}

.container {
  height: 100%;
  margin: 0px;
}

.section {
  background-color: white;
  border-radius: 15px;
  padding: 15px;
}

.main {
  margin-top: 10px;
  height: 85%;
  overflow: scroll;
}

.topBtn {
  background-color: #f26522;
  border-radius: 10px;
  border: none;
  color: white;
  padding: 12px;
  height: 45px;
}

.datebutton {
  height: 70px;
  width: 70px;
  width: same-as-height;
  font-size: 15px;
  border: none;
}

.datebuttonNotSelected {
  box-shadow: 0 0 7px #838383;
  background-color: white;
}

.datebuttonSelected {
  background-color: #f8bb17;
  border: 4px solid rgb(240, 142, 31);
}

.verticallyCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.carouselSlider {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.carouselCol {
  width: 700px;
}

.dateCarouselArrowBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.modalBtn {
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #f26522;
  font-weight: bold;
}

.modalCloseBtnContainer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.modalFormLabel {
  width: 130px;
  color: #f26522;
  font-weight: bold;
}

.hidden {
  display: none;
}

.modalFormInput {
  width: 230px;
}

.modalWidth {
  width: auto;
}

.deleteBtn {
  fill: black !important;
}
.deleteBtn:hover {
  fill: red !important;
}

.saveBtn_saved {
  fill: green !important;
}

.saveBtn_unsaved {
  fill: red !important;
}

.invalidDate {
  color: red;
  font-weight: bold;
}

.greyedOutBtn {
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: grey;
  font-weight: bold;
  cursor: not-allowed !important;
}

@media screen and (max-width: 1539px) {
  .carouselCol {
    width: 550px;
  }
}

@media screen and (max-width: 1380px) {
  .carouselCol {
    width: 400px;
  }
}
