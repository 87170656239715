.navLink {
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
  padding-right: 20px;
  /* padding-left: 10px; */
  font-weight: 600;
  /* border: 1px solid red; */
}

.navLink:hover {
  color: #ff6505;
  display: flex;
  text-align: center;
  justify-content: center;
  padding-right: 20px;
  /* padding-left: 10px; */
  font-weight: 600;
  text-decoration: none;
  /* border: 1px solid red; */
}

.navLinkDD {
  color: white;
  padding-left: 30px;
  font-size: 26px;
  font-weight: 600;
}
.navLinkDD:hover {
  color: #ff6505;
  padding-left: 30px;
  font-size: 26px;
  font-weight: 600;
  text-decoration: none;
}

.dropdownLink {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
}