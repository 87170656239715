.sectionHeaderAdmin {
  background-color: #ffba00;
  width: 50%;
  height: 60px;
  font-weight: bold;
  padding-left: 8%;
  padding-top: 12px;
  font-size: 25px;
  margin-bottom: 30px;
}

.containerSplit {
  text-align: center;
  display: flex;
  width: 100%;
}

.boxPDleft {
  width: 22%;
  margin-left: 8%;
}

.planHeader {
  font-size: 25px; 
  font-weight: bold;
  margin-bottom: 30px;
}

.plateButtonWrapper {
  margin-top: 20px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plateButtonWrapper2 {
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plateButtonWrapper3 {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.plateButtonCurrent {
  color: #ffffff;
  border: 1px solid #e4e4e4;
  background-color: #FFBA00;
  border-radius: 50%;
  font-size: 40px;
  font-weight: 600;
  outline: 0;
  width: 3em;
  height: 3em;
  background-image: url('../../../images/orange_plate.png');
  background-size: 100%;
}

.plateButton {
  color: #ffffff;
  border: 1px solid #e4e4e4;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 32px;
  font-weight: 600;
  outline: 0;
  width: 3em;
  height: 3em;
  background-image: url('../../../images/yellow_plate.png');
  background-size: 100%;
}
/* .plateButton:disabled {
  color: #ffffff;
  border: 1px solid #e4e4e4;
  background-color: #e4e4e4;
  border-radius: 50%;
  font-size: 32px;
  font-weight: 600;
  outline: 0;
  width: 3em;
  height: 3em;
  background-image: none;
  background-size: 100%;
} */
.plateButton:disabled {
  color: #ffffff;
  border: 1px solid #e4e4e4;
  background-color: #ffffff;
  opacity: 0.5;
  border-radius: 50%;
  font-size: 32px;
  font-weight: 600;
  outline: 0;
  width: 3em;
  height: 3em;
  background-image: url('../../../images/yellow_plate.png');
  background-size: 100%;
}

.plateButtonSelected {
  color: #ffffff;
  border: 1px solid #e4e4e4;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 32px;
  font-weight: 600;
  outline: 0;
  width: 3em;
  height: 3em;
  background-size: 100%;
  background-image: url('../../../images/orange_plate.png');
  background-color: #FFBA00;
  outline: 0;
}
/* .plateButtonSelected:disabled {
  color: #ffffff;
  border: 1px solid #e4e4e4;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 32px;
  font-weight: 600;
  outline: 0;
  width: 3em;
  height: 3em;
  background-size: 100%;
} */
.plateButtonSelected:focus {
  outline: 0 !important;
}
.plateButtonSelected:active {
  outline: 0 !important;
}

.buttonWrapper {
  align-content: center;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  /* border: 1px solid blue; */
}

.menuSection {
  /* margin: 50px 0px 0px 0px; */
  /*border: groove;*/
}

.subHeader2 {
  /* border: 1px solid;
  height: 50px; */
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: black;
}

.sameLine {
  display: flex;
  /* justify-items: center; */
  justify-content: center;
  align-items: center;
  /* width: 120x;
  height: 100px; */
  margin: 20px 5px 0 5px;
  /* margin-left: 10px; */
  /* border: 1px solid green; */
}
.sameLine img {
  width: 50px;
  height: 50px;
}
.sameLine p {
  padding: 15px;
  margin-bottom: 0px;
  color: black;
  display: block;
  font-size: 13px;
  font-weight: 600;
}
.sameLine button:focus {
  outline: 0;
}

/* .deliveryButtonCurrent {
  color: #000000;
  background-color: #ffffff;
  margin: 5px;
  align-content: center;
  flex: 1;
  min-width: 20%;
  font-size: 10px;
  font-weight: 600;
  outline: 0;
  height: 130px;
  width: 130px;
  border: solid;
  border-color: #ff6505;
  box-shadow: none !important;
  outline: 0;
  border-radius: 0;
} */
.deliveryButtonCurrent {
  color: #000000;
  border: solid;
  border-color: #ff6505;
  box-shadow: none !important;
  background-color: #ffffff;
  /*border-radius: 25px;*/
  /* padding: 0.5rem 0.5rem; */
  /* margin: 5px; */
  /* align-content: center; */
  /* flex: 1; */
  /* min-width: 20%; */
  /* margin-top: 0px; */
  font-size: 15px;
  font-weight: 600;
  outline: 0;
  /* height: 100px;
  width: 100px; */
  height: 7em;
  width: 7em;
  border-radius: 0;
}

.deliveryButton {
  color: #000000;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 2px #d3d3d3;
  background-color: #ffffff;
  /* background-color: #e4e4e4; */
  /*border-radius: 25px;*/
  /* padding: 0.5rem 0.5rem; */
  /* margin: 5px; */
  /* align-content: center; */
  /* flex: 1; */
  /* min-width: 20%; */
  /* margin-top: 0px; */
  font-size: 10px;
  font-weight: 600;
  outline: 0;
  /* height: 100px;
  width: 100px; */
  height: 7em;
  width: 7em;
  border-radius: 0;
}
/* .deliveryButton:disabled {
  color: #000000;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 2px #d3d3d3;
  background-color: #ccc9c9;
  margin: 5px;
  align-content: center;
  flex: 1;
  min-width: 20%;
  font-size: 10px;
  font-weight: 600;
  outline: 0;
  height: 78px;
  width: 78px;
} */
.deliveryButton:disabled {
  opacity: 0.5;
  /* cursor: not-allowed; */
  /* background-color: #e4e4e4; */
  /*border-radius: 25px;*/
  /* padding: 0.5rem 0.5rem; */
  /* margin: 5px; */
  /* align-content: center; */
  /* flex: 1; */
  /* min-width: 20%; */
  /* margin-top: 0px; */
}

.deliveryButtonSelected {
  /*background-color: #FFBA00;*/
  border: solid;
  border-color: #ff6505;
  box-shadow: none !important;
  outline: 0;
}
.deliveryButtonSelected:disabled {
  background-color: white;
  border: solid;
  border-color: #ff6505;
  box-shadow: none !important;
  outline: 0;
  opacity: 1;
}
.deliveryButtonSelected:focus {
  outline: 0 !important;
}
.deliveryButtonSelected:active {
  outline: 0 !important;
}

.chargeContainer {
  /* display: block; */
  display: flex;
  width: 370px;
  /*margin: 80px auto 0px;*/
  /* margin: 0px auto 50px; */
  /* text-align: center; */
  /*border: inset;*/
  /* float: left; */
  align-items: center;
  justify-content: center;
  height: 80px;
  border: solid;
  border-radius: 25px;
  border-color: #ff6505;
  margin-bottom: 50px;
}
.chargeContainerNarrow {
  display: block;
  width: 370px;
  /*margin: 80px auto 0px;*/
  margin: 0px auto 50px;
  text-align: center;
  /*border: inset;*/
  float: center;
}
.chargeTotal {
  border: solid;
  border-radius: 25px;
  border-color: #ff6505;
  height: 80px;
  /* justify-content: center; */
}
.chargeText {
  font-size: 20px;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  padding-right: 10px;
  /* padding-top: 20px; */
}
.chargeAmount {
  font-size: 40px;
  font-weight: bold;
  /* border: 1px solid blue */
  /* padding-top: 5px; */
  padding-left: 10px;
}
