.root {
  height: inherit;
  width: inherit;
  /* background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../images/landing.png"); */
  /* background-color: white; */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.mainLogin {
  height: 100%;
}
.mealHeader {
  height: 300px;
  text-align: center;
  padding: 4.5rem 0rem 0rem 0rem;
}

p:nth-of-type(1) {
  font-size: 30px;
  color: white;
  padding: 0px 80px;
}
p:nth-of-type(2) {
  margin-top: 20px;
  font-size: 15px;
  color: #fdca3e;
  padding: 5px 40px;
  font-weight: 600;
}

.headerItemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.headerItem {
  flex: 1;
}

.headerItem:nth-of-type(1) {
  flex: 3;
}

.loginSectionContainer {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  padding: 55px 0px 0px 0px;
}
.socialLogin {
  position: absolute;
  display:block;
  width: 80px;
  /* border: 1px solid red; */
  text-align: center;
  /* justify-content: space-evenly; */
  padding: 0px;
  /* background-color: white; */
  right: 10px;
  top: 90px
}
.socialLogin > button,
.socialLogin > span > button,
.socialLogin > div > button {
  margin-left: 20px;
  margin-right: 20px;
}

/* .googleBtn {
  background-color: none;
  border: none;
  cursor: pointer;
  width: 412px;
  height: 77px;
  padding: 0px;
  opacity: 0;
} */
/* width: "412px",
            height: "77px",
            marginBottom: "11px",
            marginLeft: "68px",
            backgroundImage: `url(${socialG})`,
            backgroundSize: "cover",
            backgroundPosition: "center", */
.btnWrapper {
  /* border: 1px dashed; */
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: center;
}
.googleBtn {
  /* background-color: none; */
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 412px;
  height: 77px;
  padding: 0px;
  background-image: url('../../images/socialGoogle.png');
  background-size: cover;
  background-position: center;
  /* opacity: 0; */
}

.googleBtnCircle{
  background-color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 0px;
  height: 50px;
  width: 50px;
  margin-left: 15px;
}
.googleBtnCircle::before {
  /* content: url(../../images/google-plus.svg); */
  content: url('../../images/Group 174.svg');
}

/* ../../images/socialApple.png */
/* .appleLogin {
  border: none;
  cursor: pointer;
  padding: 0px;
  width: 412px;
  height:77px;
  opacity: 0;
} */
.appleLogin {
  /* background-color: none; */
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 412px;
  height: 77px;
  padding: 0px;
  background-image: url('../../images/socialApple.png');
  background-size: cover;
  background-position: center;
  /* opacity: 0; */
}

.appleLoginCircle{
  background-color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 0px;
  height: 50px;
  width: 50px;
  margin-left: 15px;
}

/* .fbLogin {
  background-color: none;
  border: none;
  border: 1px dashed;
  cursor: pointer;
  padding: 0px;
  width: 412px;
  height:77px;
  opacity: 0;
} */
.fbLogin {
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 412px;
  height: 77px;
  padding: 0px;
  background-image: url('../../images/socialFb.png');
  background-size: cover;
  background-position: center;
  max-width: 100% !important;
}

@media (max-width: 590px) {
  .btnWrapper {
    /* border: 1px dashed; */
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
  }
  .fbLogin {
    border: none;
    border-radius: 15px;
    cursor: pointer;
    width: 290px;
    height: 50px;
    padding: 0px;
    background-image: url('../../images/socialFb.png');
    background-size: cover;
    background-position: center;
    max-width: 100% !important;
  }

  .appleLogin {
    /* background-color: none; */
    border: none;
    border-radius: 15px;
    cursor: pointer;
    width: 290px;
    height: 50px;
    padding: 0px;
    background-image: url('../../images/socialApple.png');
    background-size: cover;
    background-position: center;
    /* opacity: 0; */
  }

  .googleBtn {
    /* background-color: none; */
    border: none;
    border-radius: 15px;
    cursor: pointer;
    width: 290px;
    height: 50px;
    padding: 0px;
    background-image: url('../../images/socialGoogle.png');
    background-size: cover;
    background-position: center;
    /* opacity: 0; */
  }
}

.fbLoginCircle{
  background-color: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 0px;
  height: 50px;
  width: 50px;
  color: transparent;
}
.fbLoginCircle::before{
  content: url(../../images/facebook_circle.svg);
}





.loginSectionItem {
  flex: 1 100%;
  margin: 7px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginSectionInput {
  width: 270px;
  height: 31px;
  padding: 0px 15px;
  border-radius: 18px;
  background-color: #ffffffd0;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  /* margin-bottom: 5px; */
}

.loginSectionInput:focus {
  outline: none;
}

.loginSectionInput > input {
  width: 85%;
  float: left;
  background-color: transparent;
  padding-top: 3px;
  height: 90%;
  border: none;
}

.loginSectionInput > input:hover {
  outline: none;
  border: none;
}

.loginSectionInput > a {
  cursor: pointer;
  height: 100%;
  width: 10%;
  text-decoration: none;
  display: inline-flex;
  margin: 0px !important;
  padding: 0.5rem;
  text-align: center;
  justify-items: center;
}

.buttonContainer {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.button {
  width: 110px;
  height: 45px;
  color: #000000;
  border: none;
  background-color: #ff9e19;
  border-radius: 25px;
  padding: 10px;
  font-size: 1rem;
  font-weight: 500;
  margin: 0rem 0.8rem;
}

.socialLoginItem {
  margin: 20px;
  padding: 5px;
  height: 30px;
}
