.containerMeals {
  border-radius: 15px;
  margin-top: 20px;
  margin-right: 40px;
  margin-left: 40px;
  margin-bottom: 20px;
  background-color: white;
  overflow: scroll;
  flex: 1;
}

.editBusiness {
  border-radius: 15px;
  margin-top: 20px;
  margin-right: 40px;
  margin-left: 40px;
  margin-bottom: 20px;
  background-color: #fef7e0;
  overflow: scroll;
  flex: 1;
}

.editBusinessFormContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 15px 0px 25px 0px;
}

.containerCustomer {
  padding: 15px 20px;
  z-index: 1;
  border-radius: 15px;
  margin-top: 20px;
  margin-right: 40px;
  margin-left: 40px;
  background-color: white;
}

.mobile {
  display: flex !important;
  margin: 0 !important;
  padding: 0 !important;
}
.root {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

button:focus {
  outline: 0 !important;
}

.dropdown {
  border: none;
  padding: 0px;
  padding-right: 20px;
  color: black;
  background-color: white;
  width: 13vw;
  outline: none;
  display: inline-block;
  appearance: none;
  cursor: pointer;
  background: url("../../../images/dropdown.svg") no-repeat;
  background-position: right 5px top 50%;
}

.deleteIcon {
  background-image: url("../../../images/Icon\ material-delete.svg");
  background-size: 100%;
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
}

.editIcon {
  background-image: url("../../../images/Icon\ material-edit.svg");
  background-size: 100%;
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
}

.editIconSmall {
  background-image: url("../../../images/Icon\ material-edit.svg");
  background-size: 100%;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: "4px";
}

.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  border-radius: 40px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  border-radius: 50%;
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00cc00;
}

input:focus + .slider {
  box-shadow: none;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.modalForm {
  padding: 10px 30px 10px 30px;
}
.modalError {
  border: 2px solid red;
}

.businessHeader {
  display: flex;
  justify-content: right;
}

.headerText {
  color: #f26522;
  font-weight: bold;
  padding-bottom: 5px;
}

.businessHours {
  padding: 0px;
}

.businessHoursText {
  text-align: center;
}

.pageBody {
  display: flex;
  flex-direction: column;
  height: 92vh;
}
