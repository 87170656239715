.root {
    display: inherit;
    width: inherit;
    height: inherit;
}

.mealHeader {
    background-color: #ebebeb;
}

.headerItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}   

.headerItem {
    flex: 1;
}

.headerItem:nth-of-type(1) {
    flex: 3;
}

.headingContainer {
    display: flex;
    align-items: center;
    height: 40px;
}

.headingItem {
    flex: 1;
}

.subHeading {
    font-weight: bold;
    color: black;
    font-size: 15px;
    padding-left: 20px;
    margin: 0;
}

.editIcon {
    float: right;
    margin: 1rem;
}

.personalDetails {
    font-weight: normal;
    color: black;
    font-size: 15px;
    padding-left: 20px;
}

.cardContainer {
    display: flex;
    align-items: center;
    justify-content: left;
}

.cardItem {
    flex: 1 20%;
    margin: 0 15px;
    height: 40px;
    border: none;
    border-radius: 15px;
    background-color: #e2e2e2;
    color: black;
    max-width: 70px;
}

.cardItem:nth-of-type(3) {
    flex: 2;
}

.billingAddress {
    font-weight: normal;
    color: black;
    font-size: 15px;
    padding-left: 20px;
}

.orderHistoryContainer {
    display: flex;
    align-items: center;
    justify-content: left;
}

.orderHistoryItem {
    flex: 1;
}

.orderHistoryItemContianer {
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 20px;
}

.orderHistorySubItem {
    flex: 1;
    height: 70px;
}
/* 
.changePasswordInput {
    width: 250px;
    margin: 5px;
} */

.changePasswordButton {
    width: 110px;
  height: 40px;
  color: #000000;
  border: none;
  background-color: #ff9e19;
  border-radius: 25px;
  margin: 5px;
  font-size: 15px;

}

.changePasswordInput {
    margin: 5px;
    width: 270px;
    height: 31px;
    padding: 0px 15px;
    border-radius: 18px;
    background-color: #ffffffd0;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    /* margin-bottom: 5px; */
  }
  
  .changePasswordInput:focus {
    outline: none;
  }
  
  .changePasswordInput > input {
    width: 85%;
    float: left;
    background-color: transparent;
    padding-top: 3px;
    height: 90%;
    border: none;
  }
  
  .changePasswordInput > input:hover {
    outline: none;
    border: none;
  }
  
  .changePasswordInput > a {
    cursor: pointer;
    height: 100%;
    width: 10%;
    text-decoration: none;
    display: inline-flex;
    margin: 0px !important;
    padding: 0.5rem;
    text-align: center;
    justify-items: center;
  }