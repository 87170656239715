/*The format on the grid is wrong.  font: normal normal bold 24px/29px SF Pro;
is not accepted by the CSS here. It didnt work as of now.
Do not enable this
        font-family: -apple-system, BlinkMacSystemFont;
It is breaking everything.

*/
html, body {margin: 0; padding: 0; overflow-x:hidden;}

.topBackground {
  background: url("../../images/madmax-chef-cToNEm70cvE-unsplash@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* height: 680px; */
  height: 760px;
  width: 100%;
  /* width: 1920px inherit; */
  z-index: 1;
  position: relative;
  /* border: solid cyan; */
}

.gridcontainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.footerBackground {
  background-color: #f8bb17;
  height: 150px;
  position: relative;
  bottom: 0;
  /* border: 1px solid cyan; */
}

.footerBackgroundNarrow {
  background-color: #f8bb17;
  height: 200px;
  position: relative;
  /* border: 1px solid cyan; */
}

/* .footerBackgroundNarrow {
  background-color: #F8BB17;
  height: 500px;
  position: relative;
} */

.footerLogo {
  margin: 0;
  background: #f8bb17;
  position: absolute;
  top: 50%;
  left: 100%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  max-width: 300px;
  max-height: 300px;
}
.findUs {
  position: absolute;
  left: 5%;
  top: 40%;
  margin-right: 30%;
  /*transform: translate(-50%, -50%);*/
  height: auto;
  width: auto;
  max-width: 300px;
  max-height: 300px;
  /*font-family: -apple-system, BlinkMacSystemFont;*/
  font: SF Pro;
  font-size: 24px/15px;
  font-weight: medium;
  /*margin-left: 370px;	*/
}
.findUsNarrow {
  /* position: absolute; */
  /* left: 5%;
  top: 40%;
  margin-right: 30%; */
  /*transform: translate(-50%, -50%);*/
  /* height: auto; 
  width: auto; 
  max-width: 300px; 
  max-height: 300px; */
  /*font-family: -apple-system, BlinkMacSystemFont;*/
  font: SF Pro;
  font-size: 30px;
  font-weight: medium;
  /* border: 1px green solid; */
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  width: 120px;
  position: relative;
  /*margin-left: 370px;	*/
}
.footerLogoNarrow {
  margin: 0;
  /* background: white; */
  /* border: solid; */
  /* border-color: white; */
  border-radius: 10px;
  /* top: 15%;
  left: 50%; */
  left: 122px;
  /* right: 150px; */
  /* margin-right: -50%;
  transform: translate(-50%, -50%); */
  position: absolute;
  /* height: auto; 
  width: auto; 
  max-width: 300px; 
  max-height: 300px; */
  /*font-family: -apple-system, BlinkMacSystemFont;*/
  font: SF Pro;
  font-size: 24px;
  font-weight: medium;
}

.footerLinks {
  display: inline-block;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  max-width: 300px;
  max-height: 200px;
}

.footerLinksNarrow {
  display: inline-block;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  max-width: 300px;
  max-height: 200px;
}

.centerSubtitleText {
  text-align: center;
  color: white;
  font-size: 50px;
  white-space: nowrap;
  margin-top: 130px;
  /* border: 1px solid violet; */
}

.centerSubtitleNarrow {
  text-align: center;
  color: white;
  font-size: 50px;
  white-space: nowrap;
  margin-top: 20px;
  /* border: 1px solid lime; */
}

.centerSubText {
  text-align: center;
  color: white;
  white-space: nowrap;
  margin: 10px 0;
}
.centerImage {
  /* height: 140px; */
  /* width: 400px; */
  /* margin-top: 2%; */
  padding-top: 5px;
  /* padding: 5px; */
  max-height: 100%;
  /* height: 100%; */
  min-height: 110px;
  /* border: 1px dashed; */
  /* display: block; */
  /* margin-left: auto;
  margin-right: auto;
  margin-bottom: 75px;
  margin-top: -125px; */
}
.buttonsBelowTheLogo {
  height: 50px;
  width: 320px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: 15px;
}
.centerSubTextNarrow {
  text-align: center;
  color: white;
  margin: 10px 20px;
  width: 90%;
}

.orangeButton {
  background-color: #ff9e19;
  color: black;
  min-width: 220px;
  width: max-content;
  text-align: center;
  padding: 6px 20px 6px 20px;
  border-radius: 30px;
  justify-self: center;
  font-size: 16px;
  margin-top: 30px;
  cursor: pointer;
  justify-content: center;
  border-width: 0;
}
input[type="zipCode"]:focus {
  outline: none !important;
}
.footerRight {
  display: inline-block;
  text-align: center;
  margin: 0;
  position: absolute;
  /* top: 40%; */
  /* left: 85%; */
  top: 45px;
  right: 50px;
  /* margin-right: -50%;
    transform: translate(-50%, -50%);
    height: auto; 
    width: auto; 
    max-width: 300px; 
    max-height: 200px; */
  border: solid;
}

.footerRightNarrow {
  display: inline-block;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 75%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  max-width: 300px;
  max-height: 200px;
}

.ambassadorButton {
  background-color: white;
  color: black;
  min-width: 200px;
  width: max-content;
  text-align: center;
  padding: 6px 20px 6px 20px;
  border-radius: 30px;
  justify-self: center;
  font-size: 16px;
  margin-top: 30px;
  cursor: pointer;
  justify-content: center;
}

.footerButton {
  background-color: #ff9e19;
  color: black;
  text-align: center;
  border-radius: 30px;
  justify-self: center;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  justify-content: center;
  font-weight: bold;
  font-size: 25px;
}

.signUpLink {
  background-color: #ff9e19;
  color: white;
  min-width: 200px;
  width: max-content;
  text-align: center;
  padding: 6px 20px 10px 20px;
  border-radius: 30px;
  justify-self: center;
  font-size: 16px;
  margin-top: 30px;
  cursor: pointer;
  justify-content: center;
}

.gridDisplayCenter {
  display: grid;
  justify-content: center;
  align-items: center;
}
.gridDisplayRight {
  width: 100px;
  height: 200px;
  /* border: 1px dashed; */
  /* background-color: white; */
}
.whiteStripe {
  /* top: 367px; */
  /* top: 447px; */
  /* margin-top: 80px; */
  left: -320px;
  width: 100%;
  /* width: 450px; */
  /* border-radius: 15px; */
  /* border: solid; */
  height: 140px;
  /* height: 100%; */
  /* width: 500px; */
  /* width: 90%; */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-color: rgb(255, 255, 255, 0.85); */
  /* opacity: 1;	 */
  /* background-opacity: 1; */
  /* margin-top: 30px; */
  margin-top: 110px;
}
.banner {
  background-color: white;
  position: absolute;
  top: 310px;
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gridRightIcons {
  position: absolute;
  height: 50px;
  width: 50px;
  right: 25px;
  top: 252px;
  cursor: pointer;
  /* border: 1px solid red; */
}
.stepsHeaderForHowDoesSection {
  /*margin: 20px 20px 0 0px;*/
  /*flex: 1 1 30%;*/
  text-align: left;
  justify-content: left;
  align-items: left;
  /*display: grid;*/
  /*margin-left:auto;*/
}
/* .stepsHeader {
  flex: 1 1 15%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: grid;
  border: 1px solid green;
} */
.stepsHeader {
  display: inline-block;
  /* border: 1px solid green; */
  margin: 0 20px 0 20px;
}

@media (max-width: 800px) {
  .stepsHeader {
    margin: 20px 40px 20px 40px;
  }
}

.topRightText {
  position: absolute;
  top: 8px;
  right: 16px;
}
.stepsNumber {
  display: inline;
  /*width: 40px;
  height: 40px;*/
  /*border-radius: 20px;*/
  /*background-color: #ffba00;*/
  /*font-family: -apple-system, BlinkMacSystemFont;*/
  /*font: normal normal bold 19px/23px SF Pro;*/
  font-size: 19px/23px;
  font: SF pro;
  font-weight: bold;
}
input[type="zipCode"]::placeholder {
  text-align: center;
  color: black;
  font-size: 18px;
}
input[type="credentials"]::placeholder {
  font: SF Pro;
  font-weight: medium;
  font-size: 24px;
  color: black;
}
/*.stepsNumber {
  float: left;
  background-color: #ffba00;
  padding: 8px 14px;
  border-radius: 50%;
  font-weight: bold;
  margin-left: 0%;
}*/

/*.stepsNumber {
  float: left;
  background-color: #ffba00;
  padding: 8px 14px;
  border-radius: 50%;
  font-weight: bold;
  margin-left: 0%;
}*/

.stepsNumberNarrow {
  background-color: #ffba00;
  padding: 8px 14px;
  border-radius: 50%;
  font-weight: bold;
}

.stepsImage {
  display: inline;
  margin: 0 auto 20px;
  border: 2px solid #f26522;
  padding: 15px;
  border-radius: 24px;
}
.stepsImageForPurchase {
  display: inline;
  margin: 0 auto 20px;
  border: 2px solid #f26522;
  padding: 15px;
  border-radius: 24px;
  margin-top: -120px;
  margin-left: 250px;
}
.stepsImageForChoose {
  display: inline;
  margin: 0 auto 20px;
  border: 2px solid #f26522;
  padding: 15px;
  border-radius: 24px;
  margin-top: -150px;
  margin-left: 240px;
}
.stepsImageForEnjoy {
  display: inline;
  margin: 0 auto 20px;
  border: 2px solid #f26522;
  padding: 15px;
  border-radius: 24px;
  margin-top: -170px;
  margin-left: 240px;
}
.pathFromExploreToPickAPlan {
  position: relative;
  margin-left: 10%;
  margin-top: -5%;
  /*width:65vh; */
  /*height: 50vh;*/
  /*width:100%;*/
}
.pathFromPurchaseToChoose {
  position: relative;
  margin-left: 100%;
  margin-top: -80%;
  /*width:100%;*/
  /*width:3vw;*/
  /*height: 10vh;*/
}
.pathFromSelectMealsToEnjoy {
  position: relative;
  margin-left: 62%;
  margin-top: -20%;
  /*width:100vh;*/
  /*width:90%;*/
  /*width:50vh;*/
}
.stepsTitle {
  float: left;
  margin-top: 9px;
  margin-left: 15px;
  font-weight: bold;
  font: SF Pro;
}

.stepsTitleNarrow {
  margin-top: 9px;
  margin-left: 15px;
  font-weight: bold;
}

.stepsContainer {
  /*display: inline-block;*/
  text-align: left;
}

/* .stepsTitle2 {
  font-weight: bold;
  font-size: 24px/29px;
  font: SF Pro;
} */
.stepsTitle2 {
  text-align: center;
  font-weight: bold;
  font-size: 24px/29px;
  font: SF Pro;
  /* border: 1px solid red; */
}

.stepsText {
  /*color: #979797;*/
  text-indent: 0em;
  font-size: 19px/23px;
  padding: 10px 15px;
  height: 138px;
  width: 251px;
  /*font-family: -apple-system, BlinkMacSystemFont;*/
  font-weight: normal;
  font: SF Pro;
}
.stepsTextForExplore {
  /*color: #979797;*/
  text-indent: 0em;
  /*padding: 10px 15px;*/
  height: 138px;
  width: 251px;
  margin-left: 160px;
  margin-top: -160px;
  /*font-family: -apple-system, BlinkMacSystemFont;*/
  /*font: normal normal normal 19px/23px SF Pro;*/
  font: SF Pro;
  font-weight: normal;
}

.stepsTextForPurchase {
  /*color: #979797;*/
  text-indent: 0em;
  font-size: 19px/23px;
  /*padding: 10px 15px;*/
  height: 138px;
  width: 251px;
  margin-left: -160px;
  margin-top: 160px;
  font: SF Pro;
  font-weight: normal;
  /*font-family: -apple-system, BlinkMacSystemFont;*/
}
.stepsTextMid {
  color: #979797;
  font-size: 15px;
  padding: 0 50px;
  height: 140px;
  margin-bottom: 80px;
}

.stepsTextNarrow {
  /*color: #979797;*/
  font-size: 15px;
  padding: 0 50px;
  height: 80px;
  font: SF Pro;
  font-weight: normal;
  font-size: 24px;
  /*font-family: -apple-system, BlinkMacSystemFont;*/
}

/* .stepsText2 {
  font-size: 24px/29px;
  padding: 0 100px;
  font-weight: normal;
  font: SF Pro;
} */
.stepsText2 {
  font-size: 24px/29px;
  /* padding: 0 100px; */
  font-weight: normal;
  font: SF Pro;
  /* border: 1px solid blue; */
  text-align: center;
}

.couponContainer {
  text-align: center;
  margin: 5px 50px;
  flex: 0 1 300px;
  height: 30vh;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
}

.couponImage {
  margin-top: 20px;
  margin-bottom: 20px;
}

.couponText {
  color: #979797;
  font-size: 19px;
  padding: 0 50px;
}

.partnerContainer {
  height: 12vh;
  padding: 0px 20px;
}

.partnerContainerNarrow {
  text-align: center;
  border: solid;
  height: 30vh;
  justify-content: center;
}

.partnerCenteredNarrow {
  display: "inline-block";
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.partnerImage {
  height: 100%;
}

.partnerImageNarrow {
  margin-top: 10px;
  height: 100%;
  width: 60%;
}
.modal {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: auto;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.25);
}
.modal2 {
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin-left: -30%;
  top: auto;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.25);
}
.modal3 {
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin-left: -70%;
  top: auto;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.25);
}
/* .close {
  color: Black;
  float: right;
}
.close:hover {
  color: cyan;
  cursor: pointer;
} */

button {
  /* border-radius: 10px; */
  background-color: grey;
  outline: none;
}

/* button:hover {
  background-color: white;
} */
.btn {
  text-align: center;
  position: absolute;
  top: 30%;
  left: 42%;
  zoom: 1.5;
}
.ambassdorText {
  color: black;
}
.ambassdorText em {
  color: #f26522;
}
.modal_content {
  background-color: white;
  position: relative;
  top: 20%;
  left: 30%;
  width: 40%;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #f26522;
}
.imageContainer {
  display: inline-block;
  text-align: center;
  margin: 5px 10px;
  height: 349px;
  width: 204px;
  border-radius: 0px;
  background-repeat: no-repeat;
  background-size: cover;
}
.getStarted {
  position: relative;
  text-align: center;
  justify-self: center;
  align-self: center;
  justify-content: center;
  /*align-items: center;*/
  vertical-align: center;
}
.howDoesContainer {
  position: relative;
  text-align: left;
  width: 100%;
  /* height: 60px; */
  background: #f8bb17 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 100px;
}
.howDoesText {
  position: relative;
  /*left: 16px;*/
  top: 10%;
  margin-top: -56px;
  margin-left: 200px;
  /* height: 29px; */
  /*font-family: -apple-system, BlinkMacSystemFont;*
  font: normal normal bold 24px/29px SF Pro;/* */
  font-size: 24px/29px;
  font-weight: bold;
  font: SF Pro;
  letter-spacing: 0px;
  padding-top: 13px;
  padding-bottom: 13px;
}
.menuContainer {
  display: inline-block;
  text-align: center;
  margin: 0px 50px 50px;
  margin-left: 0px;
  margin-bottom: 70px;
  height: 349px;
  width: 204px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
}

.menuEye {
  opacity: 80%;
  float: left;
  height: 23%;
  margin: 2%;
}

.signUpPopUpHide {
  z-index: 1;
  display: none;
}

.signUpPopUpShow {
  height: 100%;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  position: fixed;
  display: grid;
  background-color: rgba(255, 255, 255, 0.5);
}

.signUpContainer {
  position: relative;
  justify-self: center;
  align-self: center;
  display: block;
  border: orange solid thick;
  background-color: white;
  border-radius: 20px;
  height: 80vh;
  width: 500px;
  z-index: 2;
}

.itemName {
  white-space: normal;
  border: 2px solid #f26522;
  margin-top: -15px;
  margin-left: 10px;
  width: 100%;
  height: 50px;
  padding: 10px;
  /*font-family: -apple-system, BlinkMacSystemFont;*/
  font: SF Pro;
  font-size: 14px/22px;
  font-weight: bold;
}

.carouselButtons {
  height: 50px;
  width: 30px;
  border: none;
  border-radius: 20px;
  text-align: center;
  color: #f8bb17;
  /*padding: 0px 20px;
  background-color: #F8BB17;*/
  /*margin: 120px;*/
}

.carouselButtons:focus {
  outline: none;
}

.becomeAnAmbassadorPopup {
  position: fixed;
  /* background-color: white; */
  z-index: 1;
  width: 600px;
  height: 600px;
  left: 30%;
  top: 20%;
  bottom: 10px;
  background-image: url("../../images/ambassadorNotLogin.png");
  background-size: 1600px 800px;
  background-position: center;
  background-repeat: no-repeat;
}

.poploginsignup {
  position: absolute;
  background-color: white;
  z-index: 1;
  right: 0%;
  top: -800px;
}

.becomeAnAmbassadorPopupSignin {
  position: fixed;
  z-index: 1;
  width: 540px;
  height: 666px;
  left: 30%;
  top: 20%;
  bottom: 10px;
  border: 2px solid #f26522;
  background-color: red;
  background-image: url("../../images/ambassadorLogin.png");
  background-size: 540px 666px;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
}

.becomeAmbassadorBtn {
  border-radius: 15px;
  color: white;
  background-color: #f26522;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  /* font-weight: 500; */
  cursor: pointer;
  position: absolute;
  top: 45px;
  right: 50px;
}
.becomeAmbassadorBtn:hover {
  /* color: #f26522;
  background-color: white; */
  color: #f26522;
  background-color: white;
  border: solid;
}
.narrowWrapper {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: dashed; */
}
.narrowWrapper2 {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* border: 1px dashed; */
}
.becomeAmbassadorBtnNarrow {
  border-radius: 15px;
  color: white;
  background-color: #f26522;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  /* border: dashed; */
  /* font-weight: 500; */
  cursor: pointer;
  /* position: absolute;
  top: 45px;
  right: 50px; */
}
.becomeAmbassadorBtnNarrow:hover {
  color: #f26522;
  background-color: white;
  /* border: solid; */
}

.carouselSlider {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sectionHeader {
  background-color: #ffba00;
  width: 100%;
  height: 60px;
  font-weight: bold;
  /*padding-left: 100px;*/
  padding-left: 8%;
  /* padding-top: 12px; */
  display: flex;
  align-items: center;
  font-size: 25px;
  margin-bottom: 30px;
  text-align: left;
}
.sectionHeaderHowDoes {
  background-color: #ffba00;
  width: 100%;
  height: 60px;
  font-weight: bold;
  /*padding-left: 100px;*/
  padding-left: 8%;
  /* padding-top: 12px; */
  display: flex;
  align-items: center;
  font-size: 25px;
  margin-bottom: 30px;
  text-align: left;
}
@media (max-width: 400px) {
  .sectionHeaderHowDoes {
    display: inline-block;
    justify-content: center;
    padding: 0;
    text-align: center
  }
}
@media (max-width: 800px) {
  .sectionHeader {
    justify-content: center;
    padding: 0;
    text-align: center
  }
  .sectionHeaderHowDoes {
    justify-content: center;
    padding: 0;
    text-align: center
  }
}

.whiteBtn {
  background-color: white;
  color: #F26522;
  border: 2px solid #F26522;
  padding: 15px;
  border-radius: 15px;
  width: 300px;
  font-size: 24px;
}
.whiteBtn:hover {
  background-color: #ffba00;
  color: white;
  border: 2px solid #f26522;
  padding: 15px;
  border-radius: 15px;
  width: 300px;
  font-size: 24px;
}

.viewMealsWrapper {
  /* border: 1px dashed; */
  position: absolute;
  top: 500px;
  height: 140px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.viewMeals {
  width: 320px;
  height: 57px;
  border-radius: 10px;
  font-size: 25px;
  border: none;
  text-align: center;
  color: white;
  margin-top: 15px;
  /* margin-left: 40px; */
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  background-color: #F26522;
}
.viewMeals:hover:enabled {
  /* color: #F26522;
  background-color: white; */
  background-color: #ffba00;
  /* border: solid #F26522; */
}
.viewMeals:disabled {
  opacity: 0.8;
  /* background-color: #ccc9c9; */
}

.congratsBody {
  /* border: 3px solid purple; */
  position: relative;
  margin-top: 30px;
  min-height: calc(100vh - 350px);
}

.congratsLeft {
  margin-left: 8%;
  /* border: 1px solid blue; */
}

.congratsRight {
  /* border: 1px solid red; */
  position: absolute;
  top: 0px;
  right: 8%;
  /* width: 510px; */
  width: 510px;
  max-width: 90%;
}
.congratsRight2 {
  /* border: 1px solid red; */
  position: absolute;
  top: 0px;
  right: 8%;
  /* width: 510px; */
  width: 510px;
  max-width: 90%;
  height: 100%;
}

.congratsRightWrapper {
  /* width: 510px; */
  /* border: 1px dashed; */
  border: none;
}
.congratsRightWrapper2 {
  /* width: 510px; */
  height: 430px;
  /* border: 1px dashed; */
  /* border: none; */
}


.path1 {
  /* border: 1px solid cyan; */
  width: 282px;
  height: 101px;
  /* width: 400px; */
  /* height: 100px; */
  position: absolute;
  right: 85px;
  top: 82px;
  /* top: 90px; */
  /* max-width: 292px;
  max-height: 101px; */
  background-image: url("../../images/Path 28.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.path2 {
  /* border: 1px solid red; */
  width: 192px;
  height: 40px;
  position: absolute;
  top: 250px;
  right: 150px;
  /* max-width: 192px;
  max-height: 40px; */
  background-image: url("../../images/Path 29.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.path7 {
  /* border: 1px solid red; */
  width: 192px;
  height: 40px;
  position: absolute;
  /* top: 250px; */
  /* right: 150px; */
  left: 150px;
  top: 502px;
  /* max-width: 192px;
  max-height: 40px; */
  background-image: url("../../images/Path 29.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.path3 {
  /* border: 1px solid cyan; */
  width: 336px;
  height: 144px;
  /* width: 400px; */
  /* height: 100px; */
  position: absolute;
  right: 0px;
  top: 0px;
  /* top: 90px; */
  /* max-width: 292px;
  max-height: 101px; */
  background-image: url("../../images/Path 49.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.path4 {
  /* border: 1px solid cyan; */
  width: 282px;
  height: 101px;
  /* width: 400px; */
  /* height: 100px; */
  position: absolute;
  right: 120px;
  top: 28px;
  /* top: 90px; */
  /* max-width: 292px;
  max-height: 101px; */
  background-image: url("../../images/Path 28.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.path5 {
  /* border: 1px solid cyan; */
  width: 336px;
  height: 144px;
  /* width: 400px; */
  /* height: 100px; */
  position: absolute;
  right: 75px;
  top: 183px;
  /* top: 90px; */
  /* max-width: 292px;
  max-height: 101px; */
  background-image: url("../../images/Path 49.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.path6 {
  /* border: 1px solid cyan; */
  width: 282px;
  height: 101px;
  /* width: 400px; */
  /* height: 100px; */
  position: absolute;
  right: 100px;
  top: 333px;
  /* top: 90px; */
  /* max-width: 292px;
  max-height: 101px; */
  background-image: url("../../images/Path 28.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.whatsNext {
  font: SF Pro;
  font-weight: bold;
  font-size: 25px;
  color: black;
  width: 100%;
  display: flex;
  justify-content: center;
}
.whatsNext2 {
  font: SF Pro;
  font-weight: bold;
  font-size: 25px;
  color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 125px;
}
/* style={{ 
  // marginTop: "-70px",
  // marginTop: '0px',
  position: 'absolute',
  // left: '0px',
  top: '250px',
  right: '150px',
  // border: '1px solid teal',
  maxWidth: '192px',
  maxHeight: '40px'
}}
src={pathFromRYMToHAE}
/> */
.rym {
  width: 207px;
  height: 70px;
  font: SF Pro;
  font-weight: medium;
  font-size: 24px;
  border: 2px solid #F26522;
  border-radius: 25px;
  /* padding: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: white;
  z-index: 1;
  position: absolute;
  top: 183px;
}
.rym2 {
  width: 207px;
  height: 70px;
  font: SF Pro;
  font-weight: medium;
  font-size: 24px;
  border: 2px solid #F26522;
  border-radius: 25px;
  /* padding: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: white;
  z-index: 1;
  position: absolute;
  top: 434px;
}
.sym {
  width: 207px;
  height: 70px;
  font: SF Pro;
  font-weight: medium;
  font-size: 24px;
  border: 2px solid #F26522;
  border-radius: 25px;
  /* padding: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: white;
  z-index: 1;
  position: absolute;
  top: 183px;
}
.sym:hover {
  width: 207px;
  height: 70px;
  font: SF Pro;
  font-weight: medium;
  font-size: 24px;
  /* border: 2px solid #F26522; */
  border: none;
  border-radius: 25px;
  /* padding: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: #ffba00;
  z-index: 1;
  position: absolute;
  top: 183px;
}
.hae {
  width: 207px;
  height: 70px;
  font: SF Pro;
  font-size: 24px;
  font-weight: medium;
  border: 2px solid #F26522;
  border-radius: 25px;
  /* padding: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  position: absolute;
  right: 0px;
  top: 187px;
  background-color: white;
  z-index: 1;
}
.hae2 {
  width: 207px;
  height: 70px;
  font: SF Pro;
  font-size: 24px;
  font-weight: medium;
  border: 2px solid #F26522;
  border-radius: 25px;
  /* padding: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  position: absolute;
  right: 0px;
  top: 452px;
  background-color: white;
  z-index: 1;
}

.createAccBtn {
  color: white;
  background-color: #F26522;
  border: none;
  padding: 20px;
  border-radius: 20px;
  /* width: 100%; */
  font-size: 25px;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 120px;
  cursor: pointer;
}
.createAccBtn:hover {
  background-color: #ffba00;
}

.selectMealsWrapper {
  text-align: center;
  margin-top: 15px;
  font-weight: bold;
}
.selectMealsBtn {
  color: white;
  background-color: #F26522;
  border: none;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  font-size: 25px;
  z-index: 1;
}
.selectMealsBtn:hover {
  /* color: white; */
  background-color: #ffba00;
}

.selectMealsWrapper2 {
  text-align: center;
  position: absolute;
  right: 0px;
  top: 314px;
  font-weight: bold;
}
.selectMealsBtn2 {
  color: white;
  background-color: #F26522;
  border: none;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  font-size: 25px;
  z-index: 1;
}
.selectMealsBtn2:hover {
  /* color: white; */
  background-color: #ffba00;
}

@media (max-width: 1200px) {
  .congratsRight {
    /* border: 1px solid blue; */
    margin-top: 50px;
    margin-bottom: 30px;
    height: 500px;
    position: relative;
    right: 0px;
  }
  .congratsRight2 {
    /* border: 1px solid blue; */
    margin-top: 50px;
    margin-bottom: 30px;
    /* height: 100%; */
    position: relative;
    right: 0px;
  }
  .congratsRightWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    /* border: 1px dashed; */
  }
  .congratsRightWrapper2 {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 800px;
    /* border: 1px solid lime; */
  }
  .path1 {
    /* border: 1px solid lime; */
    /* width: 282px; */
    width: 80%;
    max-width: 282px;
    margin-right: 10%;
    margin-left: 10%;
    height: 101px;
    /* width: 400px; */
    /* height: 100px; */
    position: absolute;
    right: 0;
    /* right: 85px;
    top: 82px; */
    /* top: 90px; */
    /* max-width: 292px;
    max-height: 101px; */
    background-image: url("../../images/Path 28.png");
    background-size: 100% 100%;
    /* background-size: contain; */
    /* background-repeat: no-repeat; */
  }
  .path2 {
    /* border: 1px solid lime; */
    /* width: 282px; */
    width: 80%;
    max-width: 282px;
    margin-right: 10%;
    margin-left: 10%;
    height: 101px;
    /* width: 400px; */
    /* height: 100px; */
    position: absolute;
    left: 0;
    /* right: 85px;
    top: 82px; */
    /* top: 90px; */
    /* max-width: 292px;
    max-height: 101px; */
    background-image: url("../../images/Path 28.png");
    background-size: 100% 100%;
    /* background-size: contain; */
    /* background-repeat: no-repeat; */
  }
  .path4 {
    /* border: 1px solid cyan; */
    width: 50%;
    margin-right: 25%;
    margin-left: 25%;
    max-width: 282px;
    height: 101px;
    /* width: 400px; */
    /* height: 100px; */
    position: absolute;
    right: 0px;
    top: 28px;
    /* top: 90px; */
    /* max-width: 292px;
    max-height: 101px; */
    background-image: url("../../images/Path 28.png");
    /* background-size: contain;
    background-repeat: no-repeat; */
    background-size: 100% 100%;
  }
  .path5 {
    /* border: 1px solid cyan; */
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    max-width: 336px;
    height: 144px;
    /* width: 400px; */
    /* height: 100px; */
    position: absolute;
    right: 0px;
    top: 183px;
    /* top: 90px; */
    /* max-width: 292px;
    max-height: 101px; */
    background-image: url("../../images/Path 49.png");
    background-size: 100% 100%;
  }
  .path6 {
    /* border: 1px solid cyan; */
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    max-width: 282px;
    height: 101px;
    /* width: 400px; */
    /* height: 100px; */
    position: absolute;
    right: 0px;
    top: 333px;
    /* top: 90px; */
    /* max-width: 292px;
    max-height: 101px; */
    background-image: url("../../images/Path 28.png");
    background-size: 100% 100%;
  }
  .path7 {
    /* border: 1px solid red; */
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    /* max-width: 192px; */
    /* height: 40px; */
    max-width: 282px;
    height: 101px;
    left: 0;
    /* position: absolute; */
    /* top: 150px;
    right: px; */
    /* max-width: 192px;
    max-height: 40px; */
    background-image: url("../../images/Path 28.png");
    background-size: 100% 100%;
  }
  .rym {
    width: 100%;
    /* max-width: 100%; */
    height: 70px;
    font: SF Pro;
    font-weight: medium;
    font-size: 24px;
    border: 2px solid #F26522;
    border-radius: 25px;
    /* padding: 5px; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: white;
    z-index: 1;
    /* position: absolute; */
    top: 183px;
  }
  .rym2 {
    width: 100%;
  }
  .hae {
    width: 100%;
    /* max-width: 100%; */
    height: 70px;
    font: SF Pro;
    font-weight: medium;
    font-size: 24px;
    border: 2px solid #F26522;
    border-radius: 25px;
    /* padding: 5px; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: white;
    z-index: 1;
    /* position: absolute; */
    top: 351px;
  }
  .hae2 {
    width: 100%;
    top: 600px;
  }
  .whatsNext2 {
    font: SF Pro;
    font-weight: bold;
    font-size: 25px;
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    /* border: 1px solid green; */
    padding: 0;
  }
  .createAccBtn {
    width: 100%;
  }
  .selectMealsWrapper2 {
    text-align: center;
    position: absolute;
    right: 0px;
    top: 314px;
    font-weight: bold;
    /* border: 1px solid cyan; */
    width: 100%;
  }
  /* .selectMealsBtn2 {
    width: 100%;
  } */
}

.hoorayBtn, .hoorayBtn3 {
  position: relative;
  top: 100px;
  left: 92px;
  width: 200px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  background-color: #F26522;
  color: white;
  /* padding-top: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: none;
}
.hoorayBtn:hover, .hoorayBtn3:hover {
  background-color: #ffba00;
}
.hoorayBtn2 {
  position: relative;
  top: 113px;
  left: 92px;
  width: 200px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  background-color: #F26522;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: none;
}
.hoorayBtn2:hover {
  background-color: #ffba00;
}

.hoorayBtn:hover:enabled {
  background-color: #ffba00;
}
.hoorayBtn:disabled {
  opacity: 0.5
}

.stillGrowingPopUp {
  position: absolute;
  width: 384px;
  height: 271px;
  background-color: white;
  border: 2px solid #F26522;
  bottom: 269px;
  right: 20px;
  z-index: 100;
}

.stillGrowingHeader {
  position: relative;
  top: 29px;
  left: 106px;
  width: 172px;
  height: 31px;
  font-size: 26px;
  font-weight: bold;
  z-index: 101;
}

.stillGrowingText {
  position: relative;
  top: 57px;
  left: 27px;
  width: 332px;
  height: 69px;
  font-size: 18px;
  text-align: center;
}

.hoorayPopUp {
  position: absolute;
  width: 384px;
  height: 371px;
  background-color: white;
  border: 2px solid #F26522;
  top: -400px;
  right: 20px;
  margin-left: 20px;
}

.hoorayHeader {
  position: relative;
  top: 29px;
  left: 144px;
  width: 96px;
  height: 31px;
  font-size: 26px;
  font-weight: bold;
  /* border: 1px dashed; */
}

.hoorayText {
  position: relative;
  top: 57px;
  left: 27px;
  width: 330px;
  /* height: 69px; */
  font-size: 18px;
  text-align: center;
  /* border: 1px dashed; */
}

@media (max-width: 430px) {
  .stillGrowingPopUp {
    position: absolute;
    /* width: 384px; */
    width: calc(100vw - 40px);
    height: 271px;
    background-color: white;
    border: 2px solid #F26522;
    bottom: 269px;
    right: 20px;
    z-index: 100;
  }

  .stillGrowingHeader {
    position: absolute;
    /* border: 1px dashed; */
    left: 0;
    /* top: 29px; */
    /* left: 106px; */
    /* width: 172px; */
    text-align: center;
    /* margin-top: 29px; */
    width: 100%;
    height: 31px;
    font-size: 26px;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }

  .hoorayPopUp {
    position: absolute;
    /* width: 384px; */
    width: calc(100vw - 40px);
    height: 371px;
    /* background-color: lime; */
    border: 2px solid #F26522;
    top: -400px;
    right: 20px;
    margin-left: 20px;
  }

  .hoorayHeader {
    position: static;
    /* margin-top: 29px; */
    text-align: center;
    /* display: flex; */
    margin-top: 29px;
    padding-left: 80px;
    /* top: 0; */
    justify-content: center;
    /* border: 1px dashed; */
    width: 100%;
    height: 31px;
    font-size: 26px;
    font-weight: bold;
  }

  .hoorayText {
    position: relative;
    top: 27px;
    left: 0px;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    /* width: 330px; */
    height: 140px;
    /* margin-right: 27px; */
    font-size: 18px;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    /* border: 1px inset; */
  }

  .hoorayBtn {
    position: relative;
    /* top: 100px; */
    /* left: 92px; */
    /* width: 200px; */
    width: calc(100% - 120px);
    margin-left: 60px;
    margin-right: 60px;
    height: 50px;
    top: 0px;
    /* top: 50px; */
    /* bottom: px !important; */
    left: 0px;
    font-size: 18px;
    text-align: center;
    background-color: #F26522;
    color: white;
    /* padding-top: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: none;
  }
  .hoorayBtn2 {
    position: relative;
    /* top: 100px; */
    /* left: 92px; */
    /* width: 200px; */
    width: calc(100% - 120px);
    margin-left: 60px;
    margin-right: 60px;
    height: 50px;
    top: 13px;
    /* top: 13px; */
    /* top: 50px; */
    bottom: px !important;
    left: 0px;
    font-size: 18px;
    text-align: center;
    background-color: #F26522;
    color: white;
    /* padding-top: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: none;
  }

  .hoorayBtn3 {
    position: relative;
    /* top: 170px; */
    /* left: 92px; */
    /* width: 200px; */
    margin-top: 30px;
    left: 0;
    width: calc(100% - 120px);
    margin-left: 60px;
    margin-right: 60px;
    height: 50px;
    font-size: 18px;
    text-align: center;
    background-color: #F26522;
    color: white;
    /* padding-top: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: none;
  }

  .stillGrowingText {
    position: relative;
    top: 87px;
    left: 27px;
    width: 332px;
    height: 69px;
    font-size: 18px;
    text-align: center;
  }
}

.privacyHeader {
  display:flex;
}

.privacyTitle {
  font-size:100px;
}

.bodyText {
  color:black;
  font-size:30px;
  margin-bottom:50px;
}

.bodyTitle {
  font-weight:bold;
  margin-bottom:50px;
}