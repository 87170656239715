.root {
  --height: 50px;
  --width: 60px;
  --padding: 15px;
  --light-color: #2731b4;
  width: inherit;
  height: inherit;
}
.navbar {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
  z-index: 100;
  width: 100%;
  /* width: calc(100vw - (100vw - 100%)); */
  /* width: 100vh; */
  /* max-width: 100%; */
  height: 80px;
  /* margin: auto; */
  /* padding: 0px 30px; */
  opacity: 1;
  /* border: dashed; */
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #f26522;
  /* box-shadow: 0px 5px #d6d1d1; */
}
.navbarNarrow {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
  z-index: 100;
  width: 100%;
  height: 120px;
  /* margin: auto; */
  /* padding: 0px 30px; */
  opacity: 1;
  /* border: dashed; */
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #f26522;
  /* box-shadow: 0px 5px #d6d1d1; */
}

.navbar ul {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0px;
  height: 100%;
}

.navbar a {
  color: black;
  padding: 1rem;
  margin: 0.25rem;
  height: 100%;
  text-decoration: none;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

/* .navbar > ul a:hover {
  color: var(--light-color);
  cursor: pointer;
  background-color: #5d7ebb;
  border-radius: 20px;
} */

/* .navbar > ul a:hover {
  color: var(--light-color);
  color: blue;
  cursor: pointer;
  border-radius: 20px;
} */
.navbar > ul a:active {
  transform: translateY(2px);
}

/* .narrowBtn {
  height: 50% !important;
} */

.signInBtn {
  height: 50% !important;
  width: 110px;
  border-radius: 30px;
  background-color: #ff6505;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  margin: 0.25rem;
  color: white;
  border-color: white;
  border: 2px solid;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin-top: 20px;
}

.hbButton {
  /* border: 1px dashed; */
  width: fit-content;
  /* margin: 0 0 0 0 !important;
  padding: 0 0 0 0 !important; */
  height: 20px !important;
  /* padding: 0px; */
  min-width: 320px;
  font-size: 26px !important;
  color: white !important;
  cursor: pointer;
}

.hbButton:hover {
  /* border: solid; */
  /* margin: 0 0 0 0 !important;
  padding: 0 0 0 0 !important; */
  height: 20px;
  font-size: 26px !important;
  color: #f8bb17 !important;
}

/* .signUpBtn {
  height: 50% !important;
  width: 110px;
  border-radius: 30px;
  background-color: #ff6505;
  justify-content: center;
  margin:0.25rem;
  font-weight: 500;
  font-size: 20px;
  color:white;
  border-color: white;
  border: 2px solid;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin-top: 20px;
}  */
/* .signUpBtn:focus{
  height: 50% !important;
  width: 110px;
  border-radius: 30px;
  background-color: #ff6505;
  justify-content: center;
  margin:0.25rem;
  font-weight: 500;
  font-size: 20px;  
  outline: none;
  box-shadow: none;
  padding: 0;
}  */
/* .signUpBtn:active{
  height: 50% !important;
  width: 110px;
  border-radius: 30px;
  background-color: #ff6505;
  justify-content: center;
  margin:0.25rem;
  font-weight: 500;
  font-size: 20px;  
  outline: none;
  box-shadow: none;
}  */

/* .whiteBackBtn {
  height: 50% !important;
  width: 110px;
  border-radius: 30px;
  background-color: none;
  justify-content: center;
  border:2px solid white;
  color: white;
}  */

/* .whiteBackBtn {
  height: 40px;
  width: 110px;
  border-radius: 30px;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  color: white;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}  */

/* .hamMenu {
  width: 60px;
  height: 40px;
  margin-left: 30px;
  background-image: url('../NavBarHamburger/hamburger.png');
  background-size: cover;
  cursor: pointer;
} */
/* .hamMenu:hover {
  width: 60px;
  height: 40px;
  margin-left: 30px;
  background-image: url('../NavBarHamburger/hamburger.png');
  background-size: cover;
  cursor: pointer;
  border: dashed;
} */

.loginBtn {
  position: absolute;
  right: 20px;
  height: 40px;
  width: 110px;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  color: white;
  text-overflow: ellipsis;
  /* display: block;
  white-space: nowrap;
  overflow: hidden; */
  cursor: pointer;
}
.loginBtn:hover {
  position: absolute;
  right: 20px;
  height: 40px;
  width: 110px;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f8bb17;
  color: #f8bb17;
  text-overflow: ellipsis;
  /* display: block;
  white-space: nowrap;
  overflow: hidden; */
  cursor: pointer;
}

.signUpBtn {
  position: absolute;
  right: 150px;
  height: 40px;
  width: 110px;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  /* text-align: center; */
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  color: white;
  text-overflow: ellipsis;
  /* display: block;
  white-space: nowrap;
  overflow: hidden; */
  cursor: pointer;
}
.signUpBtn:hover {
  position: absolute;
  right: 150px;
  height: 40px;
  width: 110px;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f8bb17;
  color: #f8bb17;
  text-overflow: ellipsis;
  /* display: block;
  white-space: nowrap;
  overflow: hidden; */
  cursor: pointer;
}

.nameBtn {
  position: absolute;
  right: 150px;
  height: 40px;
  /* width: 150px; */
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  /* padding-top: 0px; */
  background-color: #f26522;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid white; */
  color: white;
  text-overflow: ellipsis;
  /* display: block;
  white-space: nowrap;
  overflow: hidden; */
  cursor: pointer;
}
.nameBtn:hover {
  position: absolute;
  right: 150px;
  height: 40px;
  /* width: 150px; */
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  /* padding-top: 0px; */
  background-color: #f26522;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid white; */
  color: #f8bb17;
  text-overflow: ellipsis;
  /* display: block;
  white-space: nowrap;
  overflow: hidden; */
  cursor: pointer;
}

.nameBtnNarrow {
  position: absolute;
  right: 20px;
  height: 40px;
  /* width: 150px; */
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  /* padding-top: 4px; */
  background-color: #f26522;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  color: white;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.logoutBtn {
  position: absolute;
  right: 20px;
  height: 40px;
  width: 110px;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  color: white;
  text-overflow: ellipsis;
  /* display: block;
  white-space: nowrap;
  overflow: hidden; */
  cursor: pointer;
}
.logoutBtn:hover {
  position: absolute;
  right: 20px;
  height: 40px;
  width: 110px;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f8bb17;
  color: #f8bb17;
  text-overflow: ellipsis;
  /* display: block;
  white-space: nowrap;
  overflow: hidden; */
  cursor: pointer;
}

.logoutBtnNarrow {
  position: absolute;
  right: 20px;
  top: 0;
  height: 30px;
  width: 150px;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  /* padding-top: 4px; */
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  color: white;
  text-overflow: ellipsis;
  /* display: block;
  white-space: nowrap;
  overflow: hidden; */
  cursor: pointer;
}

.adminBtn2 {
  /* position: absolute;
  right: 150px; */
  height: 40px;
  width: 100px;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  /* text-align: center; */
  /* padding-top: 4px; */
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  color: white;
  /* text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden; */
  cursor: pointer;
}
.adminBtn2:hover {
  /* position: absolute;
  right: 150px; */
  height: 40px;
  width: 100px;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  /* padding-top: 4px; */
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f8bb17;
  color: #f8bb17;
  /* text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden; */
  cursor: pointer;
}

.showNameBtn {
  height: 50% !important;
  width: 150px;
  padding: 14px 28px;
  border-radius: 30px;
  /*background-color: rgb(255, 0, 0);*/
  background-color: #ff6505;
  justify-content: center;
  /*border:1px solid black;*/
  border: 0;
}

/* .divider{
  width:10px;
  height:auto;
  display:inline-block;
} */

.adminBtn {
  height: 50% !important;
  border: solid;
  border-radius: 30px;
}

.navContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.logout,
.logout p,
.logout i {
  color: black !important;
  font-size: 20px;
  font-weight: 450;
  padding: 5px 5px !important;
  height: 40px;
  margin: 5px !important;
}
.logout p,
.logout i {
  display: inline;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  margin: 0px !important;
}
.logout a {
  display: flex;
  width: 92px;
}
#textLogout {
  display: block;
  width: 100%;
}
#iconLogout {
  display: block;
  width: 100%;
}

.profileIcon {
  display: block;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ff9e19;
  color: white;
  font-size: 20px;
  font-weight: 500;
  border-style: none;
}
.profileIcon:focus {
  outline: none !important;
}

.profileIconWrapper {
  display: block;
  height: 100%;
  padding: 10px !important;
}

.profileIconWrapper p {
  font-size: 10px !important;
  color: black !important;
  padding: 0px !important;
  display: block;
  margin-bottom: 0px;
  font-weight: bold;
  text-align: center;
}

.modalCloseBtnContainer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.modalBtn {
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #f26522;
  font-weight: bold;
}

@media (max-width: 500px) {
  .navContainer {
    flex-flow: row;
    height: inherit;
  }

  .navElt {
    height: inherit;
    width: inherit;
    padding: 0;
    color: #727272;
    padding: 5px;
    /* overflow: hidden; */
  }

  .navElt:nth-of-type(1) {
    margin-top: 0;
  }

  a {
    width: inherit;
    height: inherit;
    text-decoration: none;
  }

  img {
    height: 50%;
  }

  p {
    padding: 0;
    margin: 0;
    color: #727272;
  }
}
