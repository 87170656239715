.sectionHeader {
  font-weight: 700;
  font-size: 22px;
  padding-left: 30px;
  /* border: solid; */
  height: 80px;
  display: flex;
  align-items: center;
}

.customerFilter {
  border: solid;
  border-width: 2px;
  width: 40%;
  height: 36px;
  /* margin-left: %; */
  /* margin-right: 2%; */
  z-index: 2;
  /* cursor: pointer; */
  color: #F26522;
  padding-left: 15px;
  font-weight: 600;
  font-size: 18px;
  font-style: italic;
  border-radius: 10px;
  min-width: 400px;
  /* display: inline-block; */
  /* display: flex; */
  align-items: center;
  text-overflow: ellipsis;
  /* padding-top: 3px; */
  display: block;
  white-space: nowrap;
  overflow: hidden;
}

.containerMeals {
  /* text-align: center; */
  /* display: inline-block; */
  /* display: flex; */
  width: 96%;
  /* border: solid; */
  border-radius: 15px;
  margin-top: 20px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: white;
  z-index: -500;
}

.containerHistory {
  /* text-align: center;
  display: flex; */
  width: 96%;
  /* border: solid; */
  border-radius: 15px;
  margin-top: 20px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: white;
  /* min-height: 300px; */
  /* margin-bottom: 20px; */
  padding-bottom: 20px;
}

.containerCustomer {
  /* text-align: center;
  display: flex; */
  width: 96%;
  height: 120px;
  z-index: 1;
  /* border: solid; */
  border-radius: 15px;
  margin-top: 20px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: white;
  /* display: flex; */
}
.containerCustomerNarrow {
  /* text-align: center;
  display: flex; */
  width: 96%;
  height: 320px;
  z-index: 1;
  /* border: solid; */
  border-radius: 15px;
  margin-top: 20px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: white;
  /* display: flex; */
}

.boxScroll {
  /* margin-left: 4%; */
  text-align: center;
  overflow-y: scroll;
  /* width: 92%; */
  width: 100%;
  flex: inline-block;
  height: 300px;
  /* border: 1px solid lime; */
}

.mealButton {
  border-bottom: 2px solid #ffba00;
  background-color: #ffffff;
  border-radius: 0px;
  padding: 0 0 0 0;
  width: 100%;
  font-size: 20px;
  outline: 0;
  height: 50px;
  background-size: 100%;
  cursor: pointer;
}

.mealButtonSelected {
  background-color: #ffba00;
  outline: 0;
}
/* .mealButtonSelected:hover {
  background-color: #ffba00;
  outline: 0;
} */

.mealButtonHeader {
  height: 50px;
  border-bottom: 2px solid #ffba00;
  /* border: dashed; */
  margin-right: 15px;
  text-align: center;
}

.mealButtonEdit {
  width: 4%;
  height: 100%;
  float: left;
  padding-top: 8px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  border: dashed;
}

.mealButtonPlan {
  width: 16%;
  height: 100%;
  float: left;
  padding-top: 8px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  border: solid;
}

.mealButtonSection {
  /* width: 16%; */
  height: 100%;
  float: left;
  padding-top: 8px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  /* border: 1px solid red; */
}
/* .mealButtonSection2 {
  width: 18%;
  height: 100%;
  float: left;
  padding-top: 8px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  border: inset;
}
.mealButtonSection3 {
  width: 18%;
  height: 100%;
  float: left;
  padding-top: 8px;
  padding-left: 10px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  border: solid;
} */

.orangeHeaderCycle {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #f26522;
  font-size: 14px;
  font-weight: 600;
  width: 9%;
  height: 40px;
  border-width: 1px;
  height: 70px;
}

.orangeHeaderSection {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #f26522;
  font-size: 14px;
  font-weight: 600;
  width: 7%;
  height: 40px;
  border-width: 1px;
  height: 70px;
  /* border: solid; */
}

.planCellOuterWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: dashed; */
  width: 7%;
  height: 70px;
  /* width: 10%; */
}
.planCellOuterWrapper2 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: inset; */
  width: 9%;
  height: 70px;
  /* width: 10%; */
}
.planCellInnerWrapper {
  align-self: flex-start;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  /* top right bottom left */
  padding: 5px 5px;    
  /* border: solid;
  border-color: red; */
  /* border: 1px solid black;
  border-radius: 5px;
  background-color: white; */
  height: 100%;
}
/* .cellContent {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
} */
/* .orangeCellContent {
  color: #f26522;
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
} */
.orangeCellContent {
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #f26522;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  border-width: 1px;
}

.histCellOuterWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: dashed; */
  border-top: solid;
  border-color: #f26522;
  border-width: 1px;
  width: 7%;
  height: 70px;
}

.histCellInnerWrapper {
  align-self: flex-start;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 5px 5px;    
  height: 100%;
}
.historyCellContent {
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  /* border: solid; */
  /* border-top: solid;
  border-color: #f26522;
  border-width: 1px; */
  /* height: 70px; */
  width: 100%;
}

.historySection {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: 7%;
  height: 40px;
  border-top: solid;
  border-color: #f26522;
  border-width: 1px;
  height: 70px;
}
/* .historySection {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: 7%;
  height: 40px;
  border-top: solid;
  border-color: #f26522;
  border-width: 1px;
  height: 70px;
} */
/* .historySection {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: 7%;
  border: solid;
  border-color: #f26522;
  border-width: 1px;
  height: 70px;
} */

.historyCycles {
  color: #f26522;
  font-size: 14px;
  font-weight: 600;
  width: 7%;
  border-top: solid;
  border-width: 1px;
  /* border: solid; */
  display: flex;
  align-items: center;
  padding-left: 5px;
  height: 70px;
}

.arrowCell {
  display: flex;
  align-items: center;
  width: 2%;
  color: #f26522;
  border-top: solid;
  border-width: 1px;
  /* border: dashed; */
}

.orangeHeaderLeft {
  border-top: solid;
  color: #f26522;
  border-width: 1px;
  font-size: 20px;
  padding-top: 4px;
  padding-left: 4px;
  width: 50%;
  height: 40px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}

.orangeHeaderRight {
  border-top: solid;
  color: #f26522;
  border-width: 1px;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 4px;
  width: 50%;
  height: 40px;
  text-align: right;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}

.orangeHeaderRightArrow {
  border-top: solid;
  /* border: solid; */
  color: #f26522;
  border-width: 1px;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 4px;
  /* width: 50%; */
  width: 46%;
  height: 40px;
  text-align: right;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}

.orangeHeaderRightUL {
  border-top: solid;
  color: #f26522;
  border-width: 1px;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 4px;
  width: 50%;
  height: 40px;
  text-align: right;
  text-decoration: underline;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}

.avatar{
  width: 70px;
  height: 70px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* border: solid;
  border-color: black;
  border-width: 1px; */
  margin-right: 10px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.orangeArrowUp {
  /* background-image: url('./static/orange_arrow_up.png'); */
  background-image: url('../../../images/orange_arrow_up.png');
  background-size: 100%;
  width: 12px;
  height: 11px;
  margin-top: 5px;
  cursor: pointer;
}
.orangeArrowHidden {
  /* background-image: url('./static/orange_arrow_up.png'); */
  /* background-image: url('../../../images/orange_arrow_up.png');
  background-size: 100%;
  width: 12px;
  height: 11px;
  margin-top: 5px;
  cursor: pointer;
  opacity: 0.5; */
  display: hidden;
}

.orangeArrowDown {
  /* background-image: url('./static/orange_arrow_down.png'); */
  background-image: url('../../../images/orange_arrow_down.png');
  background-size: 100%;
  width: 12px;
  height: 11px;
  margin-top: 5px;
  cursor: pointer;
}

.sortingArrowUp {
  /* background-image: url('./static/orange_arrow_up.png'); */
  background-image: url('../../../images/orange_arrow_up.png');
  background-size: 100%;
  width: 12px;
  height: 11px;
  min-width: 12px;
  min-height: 11px;
  margin-left: 5px;
  margin-top: 0px;
  cursor: pointer;
}

.sortingArrowDown {
  /* background-image: url('./static/orange_arrow_down.png'); */
  background-image: url('../../../images/orange_arrow_down.png');
  background-size: 100%;
  width: 12px;
  height: 11px;
  min-width: 12px;
  min-height: 11px;
  margin-left: 5px;
  margin-top: 0px;
  cursor: pointer;
}

.historyArrowUp {
  /* background-image: url('./static/orange_arrow_up.png'); */
  background-image: url('../../../images/orange_arrow_up.png');
  background-size: 100%;
  width: 12px;
  height: 11px;
  min-width: 12px;
  min-height: 11px;
  cursor: pointer;
}

.historyArrowDown {
  /* background-image: url('./static/orange_arrow_down.png'); */
  background-image: url('../../../images/orange_arrow_down.png');
  background-size: 100%;
  width: 12px;
  height: 11px;
  min-width: 12px;
  min-height: 11px;
  cursor: pointer;
}

.filterRow {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50px;
  z-index: 30;
  cursor: pointer;
  border: solid;
  border-width: 2px;
  border-color: #FFF7E0;
  border-radius: 10px;
}
.filterRow:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50px;
  z-index: 30;
  cursor: pointer;
  /* font-weight: bold; */
  border: solid;
  border-width: 2px;
  /* border-radius: 10px; */
  /* border-color: #F8BB17; */
  border-color: #f26522;
}

.cellOuterWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: dashed; */
  /* width: 10%; */
}
/* .cellOuterWrapper:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
} */

.cellOuterWrapper2 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: dashed; */
  /* width: 10%; */
}

.cellInnerWrapper {
  align-self: flex-start;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 5px 5px;
  /* border: solid;
  border-color: red; */
  /* border: 1px solid black;
  border-radius: 5px;
  background-color: white; */
  height: 50px;
}

.cellContent {
 	white-space: nowrap;
 	text-overflow: ellipsis;
 	overflow: hidden;
  /* border: dashed; */
}

.narrowInfoBox {
  /* border: solid; */
  /* min0-width: 2 */
  width: 50%;
  max-width: 300px;
  /* margin-left: 10%;
  margin-right: 10%; */
}

.sortingArrowInactive {
  /* background-image: url('./static/orange_arrow_down.png'); */
  /* background-image: url('../../../images/orange_arrow_down.png');
  background-size: 100%;
  width: 12px;
  height: 11px;
  min-width: 12px;
  min-height: 11px;
  margin-left: 5px;
  margin-top: 0px;
  cursor: pointer;
  opacity: 0.5; */
  display: none;
}
.cellOuterWrapper:hover > .cellInnerWrapper > .sortingArrowInactive{
  /* background-image: url('./static/orange_arrow_down.png'); */
  background-image: url('../../../images/orange_arrow_down.png');
  background-size: 100%;
  width: 12px;
  height: 11px;
  min-width: 12px;
  min-height: 11px;
  margin-left: 5px;
  margin-top: 0px;
  cursor: pointer;
  opacity: 0.5;
  display: inline;
}

.sectionHeader2 {
  background-color: #ffba00;
  width: 100%;
  height: 60px;
  font-weight: bold;
  /*padding-left: 100px;*/
  padding-left: 4%;
  /* padding-top: 12px; */
  display: flex;
  align-items: center;
  font-size: 25px;
  /* margin: 20px 0 0 0; */
  /* margin-bottom: 30px; */
  /* text-decoration: underline; */
}

.containerSplit {
  text-align: center;
  border: dashed;
  display: flex;
}

.boxPDleft {
  border: solid red;
  width: 30%;
  max-width: 200px;
  /* margin-left: 8%; */
  margin-left: 4%;
  display: flex;
  justify-content: center;
}

.boxPDright {
  border: solid green;
  text-align: left;
  /*border: solid;
  border-color: red;*/
  /* margin-right: 4%; */
  /* width: 66%; */
  margin-left: 4%;
  flex-grow: 1;
}

.planHeader{
  font-size: 25px; 
  font-weight: bold;
  margin-bottom: 30px;
}

.iconMeals {
  background-image: url('../../../images/orange_plate.png');
  background-size: 100%;
  /* outline: 0; */
  height: 130px;
  width: 130px;
  color: white;
  /* padding-top: 2.4rem; */
  font-size: 35px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  border: solid blue;
}

.deliveryButtonCurrent {
  color: #000000;
  background-color: #ffffff;
  margin: 5px;
  align-content: center;
  flex: 1;
  min-width: 20%;
  font-size: 10px;
  font-weight: 600;
  outline: 0;
  height: 130px;
  width: 130px;
  border: solid;
  border-color: #ff6505;
  box-shadow: none !important;
  outline: 0;
  border-radius: 0;
}

.iconTrash {
  background-image: url('../../../images/trash_icon.png');
  background-size: 100%;
  outline: 0;
  margin: auto;
  height: 65px;
  width: 50px;
  cursor: pointer;
}

.summaryRight {
  width: 22%;
  text-align: right;
  margin-top:10px;
  margin-bottom: 10px;
  font-weight: bold;
}
.summaryLeft {
  width: 34%;
  text-align: left;
  margin: 10px 0px 10px 0px;
  /*border: solid;
  border-color: red;*/
}
.summaryGray {
  width: 22%;
  text-align: right;
  margin-top:10px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #9c9c9c;
}

.tipButton {
  border: solid;
  border-color: #ff6505;
  background-color: white;
  border-width: 1px;
  height: 30px;
  width: 63px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10%;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 5px;
}

