.model_content {
  position: absolute;
  /* top: 85px; */
  /* right: 1%; */
  top: 80px;
  right: 20px;
  width: 549px;
  /* max-width: calc(100vw - 40px); */
  height: 1047px;
  background: white;
  border: 3px solid #ff8500;
  /* margin-top: 80px; */
  /* background-color: lime; */
  /* z-index: auto; */
  z-index: 100;
}

.formWrapper {
  margin-left: 60px;
  margin-right: 60px;
}

.formWrapper1 {
  display: flex;
  /* border: 1px dashed; */
  width: 100%;
}
.formWrapper2 {
  /* border: 1px dashed; */
  width: 100%;
}

.inputBoxL {
  top: 333px;
  left: 86px;
  width: 428px;
  height: 49px;
  background: #ffffff;
  border: 2px solid #ff8500;
  /* border: 1px solid red; */
  border-radius: 10px;
  margin-bottom: 6px;
  outline: none;
  width: calc(50% - 6px);
  margin-right: 6px;
}
.inputBoxR {
  top: 333px;
  left: 86px;
  width: 428px;
  height: 49px;
  background: #ffffff;
  border: 2px solid #ff8500;
  /* border: 1px solid red; */
  border-radius: 10px;
  margin-bottom: 6px;
  outline: none;
  width: calc(50% - 6px);
  margin-left: 6px;
}

.inputBox {
  top: 333px;
  left: 86px;
  /* width: 428px; */
  width: 100%;
  height: 49px;
  background: #ffffff;
  border: 2px solid #ff8500;
  /* border: 1px solid red; */
  border-radius: 10px;
  margin-bottom: 6px;
  outline: none;
}

@media (max-width: 590px) {
  .formWrapper {
    margin-left: 2%;
    margin-right: 2%;
  }

  .formWrapper1 {
    display: inline-block;
    /* border: 1px dashed; */
    width: 100%;
  }

  .inputBoxL {
    width: 100%;
    margin-right: 0;
  }
  .inputBoxR {
    width: 100%;
    margin-left: 0;
  }

  /* .inputBox {
    top: 333px;
    left: 86px;
    width: 428px;
    height: 49px;
    background: #ffffff;
    border: 2px solid #ff8500;
    border-radius: 10px;
    margin-bottom: 6px;
    outline: none;
  } */

  .model_content {
    position: absolute;
    /* top: 500px; */
    top: 80px;
    /* right: 20px; */
    right: 5px;
    /* width: 549px; */
    width: calc(100vw - 10px);
    height: 1140px;
    background: white;
    border: 3px solid #ff8500;
    /* background: cyan; */
    /* border: inset; */
    /* opacity: 1; */
    z-index: 100;
  }
}
/* .pop_content {
  position: absolute;
  top: 80px;
  right: 20px;
  width: 549px;
  height: 732px;
  background: white;
  border: 3px solid #ff8500;
  opacity: 1;
  z-index: 100;
} */

/* .close {
  color: Black;
  width: 36px;
  height: 36px;
  margin: 15px;
  text-align: center;
  border-radius: 50%;
  background-image: url("../../images/closeIcon.png");
  background-size: cover;
  background-position: center;
} */

.StreetinputBox {
  top: 333px;
  left: 86px;
  width: 428px;
  height: 49px;
  background: #ffffff;
  border: 2px solid #ff8500;
  /* border: 1px dashed; */
  border-radius: 10px;
  margin-bottom: 6px;
  outline: none;
}
.StreetinputBox::placeholder {
  color: black;
  font-size: large;
}

.signUpBtn {
  border-radius: 15px;
  background-color: #ff6505;
  /* margin-left: 49px;
  margin-top: 10px; */
  width: 400px;
  height: 71px;
  /* border-color: #ff8500; */
  color: white;
  font-size: 30px;
  /* border: 1px dashed; */
  border: none;
}
.signUpBtn:hover:enabled {
  background-color: #ffba00;
}
.signUpBtn:disabled {
  opacity: 0.5;
}

/* .pac-item {
  border: 1px solid red;
  z-index: 10000 !important;
}
.pac-container {
  position: fixed;
  border: 1px solid blue;
  z-index: 10000 !important;
}
.ship-address {
  border: 1px solid green;
  z-index: 10000 !important;
} */