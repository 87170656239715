.root {
  height: 100vh;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../../images/landing.png");
  background-size: cover;
  background-color: #696969;
  background-repeat: no-repeat;
}
.wrap_container {
  display: flex;
  align-items: center;
}
.container {
  width: 800px;
  height: 715px;
  margin: 70px auto;
  border: 2px solid #ffa500;
  border-radius: 15px;
}
.mealHeader {
  height: 200px;
  text-align: center;
  padding: 4.5rem 0rem 0rem 0rem;
  background-color: #00000074;
}
.userInfo {
  border-right: 2px solid #ffa500;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  align-self: center;
  margin: 0rem;
  padding: 0px 15px 0px 0px;
  background-color: white;
  height: 100%;
}
.explore {
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url("../../images/landing.png");
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.explore p {
  font-size: 20px;
  color: #ff9e19;
  font-weight: bold;
  padding: 0px;
  margin: auto;

  width: 100%;
}
.explore button {
  background-color: #ff9e19;
  border-radius: 20px;
  width: 120px;
  height: 40px;
  margin: 20px;
}
.explore button:focus {
  outline: none;
}
.centerBtn {
  display: flex;
  justify-content: center;
}
.headerItemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.headerItem {
  flex: 1;
}

.headerItem:nth-of-type(1) {
  flex: 3;
}

.subHeading {
  font-weight: bold;
  color: black;
  font-size: 1.2rem;
  margin: 0.6rem 0rem;
  padding-left: 20px;
}

.inputContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: inherit;
  /* margin: 5px auto; */
  padding: 10px 10px;
  /* width: 70%; */
  width: 100%;
}

.inputItem {
  flex: 1 100%;
  padding: 7px 2px;
}
.inputItem > input,
.inputItemAddress > input {
  width: 100%;
  height: 2.2rem;
  border-radius: 18px;
  border: none;
  background-color: #fff0c6;
  color: black;
  padding-left: 20px;
}

.input::placeholder {
  position: absolute;
  font-size: large;
  color: red;
}

.inputItemAddress {
  flex: 1 100%;
  padding: 7px 2px;
}

/* Unit */
.inputItemAddress:nth-of-type(1) {
  flex-basis: 50%;
}

/* Unit */
.inputItemAddress:nth-of-type(2) {
  flex-basis: 30%;
}

/* City */
.inputItemAddress:nth-of-type(3) {
  flex-basis: 30%;
}

/* State */
.inputItemAddress:nth-of-type(4) {
  flex-basis: 20%;
}

/* Zip */
.inputItemAddress:nth-of-type(5) {
  flex-basis: 20%;
}

.input {
  width: 100%;
  height: 2.2rem;
  border-radius: 18px;
  border: none;
  background-color: #fff0c6;
  color: black;
}

.buttonContainer {
  width: 100%;
  padding: 0px 20px;
  margin: -30px 10px 10px;
  display: flex;
  justify-content: center;
}

.button {
  width: 100%;
  height: 100%;
  color: #ffffff;
  border: none;
  background-color: #ff9400;
  border-radius: 25px;
  padding: 10px;
  margin-top: 2rem;
  height: 43px;
  width: 102px;
  font-size: larger;
  font-weight: 500;
  /* box-shadow: 0 3px #999; */
  box-shadow: 0px 3px 6px #00000029;
}
.button:focus {
  outline: none;
}
.button:active {
  transform: translateY(4px);
  /* box-shadow: 0px 1px #666; */
  box-shadow: 0px 3px 6px #00000029; 
}
