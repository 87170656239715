.close {
  color: Black;
  width: 36px;
  height: 36px;
  opacity: 1;
  margin: 15px;
  text-align: center;
  border-radius: 50%;
  background-image: url("../../images/closeIcon.png");
  background-size: cover;
  background-position: center;
}

.pop_content {
  position: absolute;
  /* top: 500px; */
  top: 80px;
  right: 20px;
  width: 549px;
  height: 732px;
  background: white;
  border: 3px solid #ff8500;
  /* background: lime; */
  /* border: inset; */
  opacity: 1;
  z-index: 100;
}

.loginSectionWrapper {
  width: 100%;
  /* border: 1px dashed; */
  height: 64px;
  /* display: initial; */
  /* justify-content: center; */
}

.loginSectionItem {
  margin-left: 60px;
  width: 428px;
  height: 50px;
  border-radius: 10px;
  border: 2px solid #ff8500;
  margin-bottom: 14px;
  outline: none;
}

@media (max-width: 590px) {
  .pop_content {
    position: absolute;
    /* top: 500px; */
    top: 80px;
    /* right: 20px; */
    right: 5px;
    /* width: 549px; */
    width: calc(100vw - 10px);
    height: 732px;
    background: white;
    border: 3px solid #ff8500;
    /* background: lime; */
    /* border: inset; */
    opacity: 1;
    z-index: 100;
  }

  .loginSectionWrapper {
    width: 100%;
    /* border: 1px inset; */
    height: 64px;
    display: flex;
    justify-content: center;
  }
  
  .loginSectionItem {
    /* margin-left: 60px; */
    /* width: 428px; */
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
    max-width: 428px;
    height: 50px;
    border-radius: 10px;
    border: 2px solid #ff8500;
    margin-bottom: 14px;
    outline: none;
  }
}

/* .signInBtn {
  border-radius: 8px;
  background-color: #ff8500;
  border: 1px solid black;
  margin-left: 49px;
  margin-top: 10px;
  width: 452px;
  height: 71px;
  border-color: #ff8500;
} */
.signInBtn {
  border-radius: 15px;
  background-color: #ff6505;
  /* margin-left: 49px;
  margin-top: 10px; */
  width: 400px;
  max-width: 96%;
  height: 71px;
  /* border-color: #ff8500; */
  color: white;
  font-size: 30px;
  border: none;
}
.signInBtn:hover:enabled {
  background-color: #ffba00;
}
.signInBtn:disabled {
  opacity: 0.5;
}

.inputContainer i {
  position: absolute;
}
.inputContainer {
  width: 100%;
  margin-bottom: 10px;
}

/*--------- pop up stuf ---*/
.errorTotal {
  display: flex;
  border: solid;
  border-radius: 25px;
  border-color: #ff6505;
  height: 80px;
  justify-content: center;
}
.errorText {
  font-size: 20px;
  padding-top: 20px;
}
.errorAmount {
  font-size: 40px;
  font-weight: bold;
  padding-top: 5px;
  padding-left: 20px;
}
.errorHeader {
  font-size: 40px;
  font-weight: bold;
  padding-top: 5px;
  padding-left: 20px;
  margin-top: 50px;
}

.errorModalPopUpHide {
  z-index: 1;
  display: none;
}

.errorModalPopUpShow {
  height: 100%;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  position: fixed;
  display: grid;
  background-color: rgba(255, 255, 255, 0.5);
}

.errorModalContainer {
  position: relative;
  justify-self: center;
  align-self: center;
  display: block;
  border: #ff6505 solid;
  background-color: white;
  height: 350px;
  width: 450px;
  z-index: 2;
}

.errorModalButtonMenu {
  text-decoration: none;
  color: black;
  font-weight: 450;
  height: fit-content;
  text-align: center;
  margin-left: 50px;
  margin-top: 54px;
  font-size: 18px;
  cursor: pointer;
}

.errorModalButtonMenu:hover {
  text-decoration: none;
  color: #ff9400;
}

.errorContainer {
  display: block;
  width: 370px;
  /*margin: 80px auto 0px;*/
  margin: 0px auto 0px;
  text-align: center;
}

.errorCancelButton {
  background-image: url("../../images/x_button.png");
  z-index: 2;
  float: right;
  position: absolute;
  top: 0px;
  left: 370px;
  background-size: 100%;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  cursor: pointer;
}

.loginErrorModal {
  /* border: 1px dashed; */
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: white;
  border: solid #ff6505;
  /* height: 50%; */
  /* width: 85%; */
  width: 460px;
  z-index: 2002;
  /* height: initial; */
  display: inline-block;
  justify-content: center;
  height: fit-content;
  margin-top: 50px;
}

.loginErrorHeader {
  font-size: 30px;
  font-weight: bold;
  /* padding-top: 5px; */
  /* margin-top: 15px; */
  margin: 60px 50px 15px 50px;
  text-align: center;
  /* border: 1px dashed; */
}

.loginErrorText {
  font-size: 24px;
  /* padding-top: 20px; */
  /* border: 1px dashed; */
  text-align: center;
  /* font-weight: 0; */
  margin: 22px;
}

.socialBtnWrapper {
  /* border: 1px dashed; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 15px 60px 15px;
}

.socialBtn {
  /* width: 300px; */
  max-width: 90%;
  width: 350px;
  /* width: 90%; */
  height: 60px;
  border-radius: 10px;
  padding: 10px;
  background-size: cover;
  background-position: center;
  border: none;
}

.fbLogin2 {
  width: 300px;
  max-width: 90%;
  height: 60px;
  border-radius: 10px;
  padding: 10px;
  background-image: url("../../images/socialFb.png");
  background-size: cover;
  background-position: center;
  border: none;
}

.orangeBtnWrapper {
  /* border: 1px dashed; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
}
.orangeBtn {
  max-width: 90%;
  width: 350px;
  color: white;
  background-color: #ff6505;
  height: 60px;
  border-radius: 10px;
  padding: 10px;
  background-size: cover;
  background-position: center;
  border: none;
  font-size: 20px;
}
.orangeBtn:hover {
  background-color: #ffba00;
}

.linkText {
  text-decoration: underline;
  cursor: pointer;
}
.linkText:hover {
  color: #ff6505;
}