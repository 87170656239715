.root {
  height: 100vh;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../../images/landing.png");
  background-size: cover;
  background-color: #696969;
  background-repeat: no-repeat;
}
.wrap_container {
  display: flex;
  align-items: center;
}
.container {
  width: 800px;
  height: 715px;
  margin: 70px auto;
  border: 2px solid #ffa500;
  border-radius: 15px;
}

.userInfo {
  border-right: 2px solid #ffa500;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  align-self: center;
  margin: 0rem;
  padding: 0px 15px 0px 0px;
  background-color: white;
  height: 100%;
}
.explore {
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url("../../images/landing.png");
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.explore p {
  font-size: 20px;
  color: #ff9e19;
  font-weight: bold;
  padding: 0px;
  margin: auto;

  width: 100%;
}
.explore button {
  background-color: #ff9e19;
  border-radius: 20px;
  width: 120px;
  height: 40px;
  margin: 20px;
}
.explore button:focus {
  outline: none;
}
.centerBtn {
  display: flex;
  justify-content: center;
}

.button {
  width: 100%;
  height: 100%;
  color: #ffffff;
  border: none;
  background-color: #ff9400;
  border-radius: 25px;
  padding: 10px;
  margin-top: 2rem;
  height: 43px;
  width: 102px;
  font-size: larger;
  font-weight: 500;
  box-shadow: 0 3px #999;
}
.button:focus {
  outline: none;
}
.button:active {
  transform: translateY(4px);
  box-shadow: 0px 1px #666;
}

.headerItemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.headerItem {
  flex: 1;
}

.headerItem:nth-of-type(1) {
  flex: 3;
}

.loginSectionContainer {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  padding: 55px 0px 0px 0px;
}
.socialLogin {
  display: flex;
  width: 50%;
  margin: auto;
  text-align: center;
  justify-content: space-evenly;
  padding: 0px;
}
.socialLogin > button,
.socialLogin > span > button,
.socialLogin > div > button {
  margin-left: 20px;
  margin-right: 20px;
}
.googleBtn {
  background-color: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 0px;
  height: 32.9px;
  width: 32.9px;
}

.googleBtn::before {
  content: url(../../images/google-plus.svg);
}

.appleLogin {
  background-color: black;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 0px;
  height: 32.9px;
  width: 32.9px;
}

.fbLogin {
  background-color: none;
  border: none;
  /* border: 1px dashed; */
  cursor: pointer;
  border-radius: 50%;
  padding: 0px;
  height: 32.9px;
  width: 32.9px;
  color: transparent;
}

.fbLogin::before {
  content: url(../../images/facebook_circle.svg);
}

.loginSectionItem {
  flex: 1 100%;
  margin: 7px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginSectionInput {
  width: 270px;
  height: 31px;
  padding: 0px 15px;
  border-radius: 18px;
  background-color: #ffffffd0;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  /* margin-bottom: 5px; */
}

.loginSectionInput:focus {
  outline: none;
}

.loginSectionInput > input {
  width: 85%;
  float: left;
  background-color: transparent;
  padding-top: 3px;
  height: 90%;
  border: none;
}

.loginSectionInput > input:hover {
  outline: none;
  border: none;
}

.loginSectionInput > a {
  cursor: pointer;
  height: 100%;
  width: 10%;
  text-decoration: none;
  display: inline-flex;
  margin: 0px !important;
  padding: 0.5rem;
  text-align: center;
  justify-items: center;
}

.buttonContainer {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.button {
  width: 110px;
  height: 45px;
  color: #FFFFFF  ;
  border: none;
  background-color: #ff9e19;
  border-radius: 25px;
  padding: 10px;
  font-size: 1rem;
  font-weight: 500;
  margin: 0rem 0.8rem;
  box-shadow: 0px 3px 6px #00000029;
}

.socialLoginItem {
  margin: 20px;
  padding: 5px;
  height: 30px;
}
