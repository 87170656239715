html,
body {
  height: 100%;
}

.root {
  width: 100%;
  height: 100%;
  --navBarWidth: 80px;
  --navBarHeight: 0px;
}

.sideNavBar {
  width: var(--navBarWidth);
  height: 100%;
  position: fixed;
  background-color: brown;
  overflow-x: hidden;
}

.mainApp {
  margin-left: var(--navBarWidth);
  width: calc(100vw - var(--navBarWidth));
  height: calc(100vh - var(--navBarHeight));
}

.headerIcon {
  color: #727272;
  margin: 1rem;
}

.title {
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: block;
  margin-bottom: 20px;
}

.mainTitle {
  font-size: 25px;
  font-weight: bolder;
  margin: 0px;
}

.subTitle {
  font-size: 15px;
  font-weight: 700;
  color: #727272;
  margin: 0px;
}

.bottomNavBar {
  display: none;
  background-color: #ebebeb;
  height: var(--navBarHeight);
}

/* General icon buttons*/
.icon-button {
  background-color: inherit;
  border: none;
}

.novSpace {
  margin: 0;
  padding: 0;
}

/* Choose your Meal */

.mealMain {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  overflow: visible;
  position: relative;
}

.headericon {
  color: #727272;
  margin: 1rem;
}
.meal-header {
  display: flex;
  flex-direction: row;
}
.fa-bars {
  flex: 3;
}
.fa-bell .fa-share-alt .fa-search {
  flex: 1;
}
.title {
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: block;
  margin-bottom: 5px;
}

#chooseYourPlan {
  font-size: 25px;
  font-weight: bolder;
  margin: 0px;
}

#local {
  font-size: 15px;
  font-weight: 700;
  color: #727272;
  margin: 0px;
}

.mealselectmenu {
  background-color: #ffffff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

#mealdays {
  font-weight: bold;
  color: black;
  font-size: 15px;
  margin: 1rem 0rem;
}

.meal-number {
  font-weight: bold;
  color: black;
  font-size: 15px;
  margin: 1rem 0rem;
  text-align: center;
}

.meal-button {
  color: #5a5a5a;
  border: 2px solid #727272;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 1rem 2rem;
  margin: 5px;
  align-content: center;
  flex: 1;
}
.button-wrapper {
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* .meal-button:hover {
  background-color: #eeeeee;
  flex: 1;
} */

.internal-titles {
  font-weight: 600;
  color: black;
  font-size: 17px;
  margin: 0.5rem 0rem;
}

.payment-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.payment-button {
  color: #5a5a5a;
  border: 2px solid #727272;
  background-color: #ffffff;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: 10px;
  padding: 20px;
}
/* .payment-button:hover {
  background-color: #eeeeee;
} */
.flexclass {
  display: flex;
}

#totalamount {
  font-size: 1rem;
  font-weight: 600;
  flex: 2;
  border: 2px solid #727272;
  border-radius: 25px;
  padding: 5px;
  text-align: center;
  margin-right: 2rem;
  color: #5a5a5a;
}

#done-button {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  flex: 1;
  color: #ffffff;
  border: 2px solid #a1a1a1;
  background-color: #a1a1a1;
  border-radius: 25px;
  padding: 10px;
}

.meal-footer {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ebebeb;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.meal-footer p {
  font-size: 10px;
  margin: 0px;
  padding: 0px 10px 0px 10px;
}

.footer-icon-tray {
  display: block;
  color: #727272;
  padding: 5px;
}

/* Select Meal Page */

.selection-styles {
  font-weight: 600;
  margin-top: 0.9rem;
  height: 1.7rem;
  width: 5rem;
  flex: 1;
  text-align: center;
  border: 2px solid #e09d51;
  border-radius: 25px;
  align-content: center;
  margin: 10px;
}

.selected-days {
  color: "#d3872f";
  background-color: #e09d51;
}

.indicator-wrapper {
  margin: auto auto;
  display: flex;
  justify-content: flex-start;
  /* align-content: left; */
  text-align: center;
  flex-direction: row;
  height: 3rem;
  width: 100%;
}
.dishicon {
  position: relative;
  height: 20px;
  width: 20px;
  top: 5px;
  padding-left: 10px;
  margin-right: 20px;
}

.meal-selection-indicator {
  flex: 1;
  padding: 0px 10px;
  background-color: #ebebeb;
}

#left-indicator {
  border-radius: 50px 0px 0px 0px;
  padding-left: 15px;
}
#right-indicator {
  border-radius: 0px 0px 50px 0px;
  padding-right: 15px;
}

.menu-items-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  padding-left: 10px;
  margin-bottom: 4rem;
}

#menuItem-title {
  font-size: 0.7rem;
  font-weight: bold;
  margin: 0px;
  padding-left: 5px;
}
#menuItem-desc {
  margin: 0px;
  padding-left: 5px;
  font-size: 0.6rem;
}
#favorite {
  position: absolute;
  color: #8b0a5a;
  top: 0;
  left: 0;
  padding: 0.5rem;
  font-size: 20px;
}

.menu-elements {
  color: #777777;
  font-size: 1rem;
  text-align: center;
  position: absolute;
  margin: 0;
  position: absolute;
  margin: 0.3rem;
  background-color: white;
  border-radius: 50%;
  border: none;
  box-shadow: 1px 1px #888888;
  white-space: nowrap;
  vertical-align: baseline;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0rem;
}

#minus-button {
  bottom: 0;
  left: 0;
  z-index: 2;
}

#plus-button {
  bottom: 0;
  right: 0;
  z-index: 2;
}

.footer-icons {
  height: 1.5em;
  width: 1.5rem;
}

.menuitem-individual {
  height: 12rem;
  width: 10rem;
}
.pickers {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  text-align: center;
  margin-top: 0.3rem;
  height: 1.7rem;
  width: 15rem;
  position: relative;
  border: none;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 600;
  padding: 0.2rem 0rem;
  background-color: #e09d51;
}

.sticky-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: sticky;
  width: 100%;
  top: 0px;
  z-index: 2;
}

.suprise-skip-save {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
}

.bm-burger-button {
  position: fixed;
  width: 10px;
  height: 8px;
  left: 30px;
  top: 39px;
  margin-bottom: 10px;
  padding: 6px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
  margin-bottom: 10px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  color: #c2bcbc;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffffff;
}

/* General sidebar styles */
.bm-menu {
  background: #000000;
  font-size: 20px;
  align-content: center;
  text-align: center;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #000000;
}
.bm-item {
  height: 20px;
}

/* Wrapper for item list */
.bm-item-list {
  color: #000000;
  margin: 10px 10px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item {
  color: white;
  text-align: left;
  margin-left: 4rem;
  padding: 0.5rem;
}
/* .menu-item:hover {
  color: #c2bcbc;
} */

@media (max-width: 500px) {
  .root {
    --navBarWidth: 0px;
    --navBarHeight: 60px;
  }
  .sideNavBar {
    display: none;
  }
  .bottomNavBar {
    display: block;
    position: absolute;
    bottom: 0px;
    width: inherit;
  }
}

@media only screen and (min-width: 600px) {
  .landing {
    flex-direction: column;
  }
  .mainPanel {
    display: flex;
    flex-direction: row;
  }
  .MainImage {
    width: 35rem;
    height: 40rem;
    z-index: 1;
  }
  .info {
    display: flex;
    flex: 2;
    width: 100%;
  }
  .loginPanel {
    position: relative;
    background-color: #f3f3f3;
    text-align: center;
    width: 100%;
    bottom: 10px;
    flex: 1;
    height: 100vh;
  }
  .signUp {
    width: 5rem;
    height: 2rem;
    padding: 0.3rem;
    background-color: #2a82a5;
    color: white;
  }
  .mainApp {
    width: 60rem;
    padding-left: 20%;
  }
  .indicator-wrapper {
    width: 400px;
  }
}
